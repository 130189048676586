import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid"
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
  
import './../LayoutBooking.scss';

export default class LayoutBookingAdditionalInfo extends Component {
    render() {
        return (
            <React.Fragment>
                <Box className="f_header">
                    <Typography variant="h4" component="h3">
                        <span className="f_header_step">{this.props.idx}.</span> Did we miss anything? 
                    </Typography>
                    <Typography>
                        Do you have any additional questions you might want to know?
                    </Typography>
                </Box>
              
                <Grid component="section" container spacing={3} className="f_client-information">
                        <Grid item xs={12} md={12}>
                            <TextField 
                                id="additional_questions" 
                                name="additional_questions" 
                                label="Additional Questions"  
                                multiline={true}
                                defaultValue={this.props.booking.additional_questions} 
                                rows={4}
                                rowsMax={4}
                                variant="outlined"  
                                disabled={this.props.isDisabled}
                                onChange={this.props.handleInputChange} 
                                fullWidth 
                            />
                        </Grid> 
                </Grid>
            </React.Fragment>
        );
    }
}