import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from "@material-ui/core/Container"
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid"
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';


import CommonFollowUs from '../Common/FollowUs'

import '../Layout/LayoutBooking.scss';
import './Pricing.scss';

const basePackage = {
        defaults : [
            {title:"Unlimited prints (2x6 strip)**", description:"Unlimited prints for each person in the picture", price: 0, packages:[1,2,3]},
            {title:"Pre-designed templates", description:"Choose from a variety of templates for different occasions", price: 0, packages:[1,2,3]},
            {title:"Choice of backdrop", description:"An assortment of backdrops that fits your theme", price: 0, packages:[1,2,3]},
            {title:"Party props", description:"Unique and fun props for guests to use", price: 0, packages:[1,2,3]},
            {title:"2 on site attendants", description:"Friendly, professional and delivery top service", price: 0, packages:[1,2,3]},
            {title:"On-site printing", description:"Get your prints within seconds", price: 0, packages:[1,2,3]},
            {title:"Password protected online photo gallery", description:"Relive your day/night, save your photos and send them to others", price: 0, packages:[1,2,3]},
            {title:"Set up & removal", description:"Seamless set up and removal of our booth", price: 0, packages:[1,2,3]},
        ]
}

const baseVBPackage = {
        defaults : [
            {title:"Password protected online photo gallery", description:"Relive your day/night, save your photos and send them to others", price: 0, packages:[1,2,3]},
            {title:"Password protected booth", description:"You can create a password only for your guests to use to take pictures", price: 0, packages:[1,2,3]},
            {title:"Custom designed template", description:"Let us know your theme and we will custom design a template for you. (Up to 2 revisions)", price: 0, packages:[1,2,3]},
            {title:"Accessible through desktop and smartphone", description:"Your guest will be able to take pictures from their desktop or phone", price: 0, packages:[1,2,3]},
            {title:"Branding", description:"You will have the option to add your event marquee on the start screen", price: 0, packages:[1,2,3]},
            {title:"Filters", description:"There will be a variety of filters for your guests to use when editing their image", price: 0, packages:[1,2,3]},
            {title:"Stickers", description:"Guest will have the ability to add sticker to their photo", price: 0, packages:[1,2,3]}
        ]
}

export default class Pricing extends Component {

    constructor(props) {
        super(props);

        this.state = {
           basePrice : 0,
           type: "virtual-booth",
           booking : {
            package_add_on:[]
           }
        }
    }

    componentDidMount = () => {
        // append class
        document.getElementsByTagName("BODY")[0].className="p-pricing"
        this.setState({
            basePrice : this.props.booking.package_price
        })
    }
    
    componentWillUnmount = () =>{
        // remove class
        document.getElementsByTagName("BODY")[0].className=""
    }

    handleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;  

        this.props.handleUpdateBooking(name, value)
    }

    handlePriceChange = (event, value) => {
        if (this.props.booking.package_hour !== value) {
            this.props.handleUpdateBooking("package_hour", value)
        }
    }
    handleCorporateQuote = () => {
        window.location.href = "mailto:hello@brstory.ca?subject=Corporate Event Quote&body=Hello, please answer the questions below and we'll get back to your shortly%0A%0ACoporate Name:%0AApproximately number of guests:%0AEvent Date and Time:%0ALocation:";
    }

    handleTypeSelection = (type) => {
        this.setState({
            type : type
        })
    }
    handleCheckboxChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let lst = [];  
        
        if (e.target.name === "package_add_on") {
            let addOns = this.state.booking.package_add_on
            if(!addOns.includes(value)) {
                addOns.push(value)
            } else {
                addOns.splice(addOns.indexOf(value), 1);
            }

            lst = addOns
        }

        this.setState({
            booking : {
                ...this.state.booking,
                [name]: lst
            }
        })
    }
   
    renderPackageDefaults() {
        let output = []

            basePackage.defaults.forEach(function (item, i) { 
                let price = item.price === 0 ? "Included" : "";
                output.push(
                    <ListItem key={i}>
                        {/* <ListItemAvatar>
                            <Avatar>
                            <FolderIcon />
                            </Avatar>
                        </ListItemAvatar> */}
                        <ListItemText
                            primary={<Typography variant="h6" component="h6" >{item.title}</Typography>}
                            secondary={<Typography >{item.description}</Typography>}
                        />
                        <ListItemSecondaryAction>
                            <Typography variant="h5" component="h6">{price}</Typography>
                        </ListItemSecondaryAction>
                    </ListItem>
                )
            })
        return output
    }

    renderVBPackageDefaults() {
        let output = []

            baseVBPackage.defaults.forEach(function (item, i) { 
                let price = item.price === 0 ? "Included" : "";
                output.push(
                    <ListItem key={i}>
                        {/* <ListItemAvatar>
                            <Avatar>
                            <FolderIcon />
                            </Avatar>
                        </ListItemAvatar> */}
                        <ListItemText
                            primary={<Typography variant="h6" component="h6" >{item.title}</Typography>}
                            secondary={<Typography >{item.description}</Typography>}
                        />
                        <ListItemSecondaryAction>
                            <Typography variant="h5" component="h6">{price}</Typography>
                        </ListItemSecondaryAction>
                    </ListItem>
                )
            })
        return output
    }

    renderAddOnsList() {
        let output = []

            this.props.addOns.forEach(function (item, i) { 
                let priceExt = ""

                if (item.type === "time") {
                    priceExt = "/hr"
                }

                // const notApplicable = ["idle_hours", "guest_book", "print_template", "4x6_unlimited" ]

                output.push(
                    <ListItem key={i}>
                        {/* <ListItemAvatar>
                            <Avatar>
                            <FolderIcon />
                            </Avatar>
                        </ListItemAvatar> */}
                        <ListItemText
                            primary={<Typography variant="h6" component="h6" >{item.title}</Typography>}
                            secondary={<Typography >{item.description}</Typography>}
                        />
                        <ListItemSecondaryAction>
                            <Typography variant="h5" component="h6">${item.price}{priceExt}</Typography>
                        </ListItemSecondaryAction>
                    </ListItem>
                )
               

               
            })
        
        return output
    }

    renderVirtualBooth() {
        let output = ""

            if(this.state.type === "virtual-booth") {
                output = 
                <>
                    <Box component="section" className="price">
                        <Container maxWidth="lg"  fixed>
                            <Grid  container spacing={3} >
                                <Grid item xs={12} md={3}>
                                    <Box className="f_header">
                                        <Typography variant="h4" component="h3">
                                            Pricing
                                        </Typography>
                                    </Box>        
                                </Grid>
                                <Grid item xs={12} md={9}>
                                    <Typography variant="h5" component="h5">
                                        The virtual booth price is really simple. For ${this.props.virtualBooth.price}, you will have a full day access.
                                    </Typography>  
                                 
                            
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>

                    <Box component="section" className="feature">
                        <Container maxWidth="lg"  fixed>
                            <Grid  container spacing={3} >
                                <Grid item xs={12} md={3}>
                                    <Box className="f_header">
                                        <Typography variant="h4" component="h3">
                                            Package
                                        </Typography>
                                    </Box>        
                                </Grid>
                                <Grid item xs={12} md={9}>
                                    <Typography variant="h5" component="h5">
                                        Your package will includes the following features with no additional costs. 
                                    </Typography>  
                                    <List className="feature-list">
                                        {this.renderVBPackageDefaults()}
                                    </List>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>

                    <Box component="section" className="feature">
                        <Container maxWidth="lg" className="feature add-on" fixed>
                            <Grid  container spacing={3} >
                                <Grid item xs={12} md={3}>
                                    <Box className="f_header">
                                        <Typography variant="h4" component="h3">
                                            Add Ons
                                        </Typography>
                                    </Box>        
                                </Grid>
                                <Grid item xs={12} md={9}>
                                    <Typography variant="h5" component="h5">
                                            Want more for your event? We have additional features that you can add-on to your package at additional cost. 
                                    </Typography>  
                                    <List className="feature-list">
                                        {this.renderAddOnsList()}
                                    </List>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </>
            }

        return output
    }

    renderPersonalContent() {
        let output = ""

            if(this.state.type === "personal") {
                output = 
                <>
                    <Box component="section" className="price">
                        <Container maxWidth="lg"  fixed>
                            <Grid  container spacing={3} >
                                <Grid item xs={12} md={3}>
                                    <Box className="f_header">
                                        <Typography variant="h4" component="h3">
                                            Pricing
                                        </Typography>
                                    </Box>        
                                </Grid>
                                <Grid item xs={12} md={9}>
                                    <Typography variant="h5" component="h5">
                                        Our pricing is really simple. The base price is based on the hours you are planning to book with us. From there, you can customize your package based on our add-on services. 
                                    </Typography>  
                                    <Grid  container spacing={3} className="price-content">
                                        
                                        <Box className="price-content_quote">
                                            <Typography variant="h4" component="h3">
                                                For
                                                <TextField  
                                                        inputProps={{ min: "2", max: "10", step: "1" }}
                                                        required 
                                                        defaultValue={this.props.booking.package_hour} 
                                                        type="number"  
                                                        name="package_hour" 
                                                        disabled={this.props.isDisabled}
                                                        onChange={this.handleInputChange} 
                                                />
                                                hours; your base price will be <strong>${this.props.booking.package_price}</strong>
                                            </Typography>  
                                        </Box>
                                    
                                    </Grid>
                            
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>

                    <Box component="section" className="feature">
                        <Container maxWidth="lg"  fixed>
                            <Grid  container spacing={3} >
                                <Grid item xs={12} md={3}>
                                    <Box className="f_header">
                                        <Typography variant="h4" component="h3">
                                            Package
                                        </Typography>
                                    </Box>        
                                </Grid>
                                <Grid item xs={12} md={9}>
                                    <Typography variant="h5" component="h5">
                                        Your package will includes the following features with no additional cost. 
                                    </Typography>  
                                    <List className="feature-list">
                                        {this.renderPackageDefaults()}
                                    </List>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>

                    <Box component="section" className="feature">
                        <Container maxWidth="lg" className="feature add-on" fixed>
                            <Grid  container spacing={3} >
                                <Grid item xs={12} md={3}>
                                    <Box className="f_header">
                                        <Typography variant="h4" component="h3">
                                            Add Ons
                                        </Typography>
                                    </Box>        
                                </Grid>
                                <Grid item xs={12} md={9}>
                                    <Typography variant="h5" component="h5">
                                            Want more for your event? We have additional features that you can add-on to your package at additional cost. 
                                    </Typography>  
                                    <List className="feature-list">
                                        {this.renderAddOnsList()}
                                    </List>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </>
            }

        return output
    }

    renderCorporateContent() {
        let output = ""
        
        return output
    }

    render() {
        let personal = ""
        let corporate = ""

            if(this.state.type === "personal") {
                personal = "--selected"
                
            } else {
                corporate = "--selected" 
            }

        return  (
            <>

            <Box component="div" className="page-hero --sub-page --center"> 
                {/* <CommonBGLines/> */}
                <Container maxWidth="lg" fixed >
                    {/* <Typography variant="h5" component="h1">
                        Package
                    </Typography> */}
                    <Typography variant="h1" component="h1" className="--icon">
                        Flexible Pricing
                    </Typography> 
                </Container>
            </Box>
            <Box component="main" >
                {/* <CommonShape/> */}
                <Box component="section" className="--overlap-effect">
                    <Container  maxWidth="lg" fixed >
                        <Grid  container spacing={3} className="client">
                            <Grid item xs={12} md={4}>
                                <Box className={`client-type ${personal}`} onClick={this.handleTypeSelection.bind(this, "personal")}>
                                        <Box className="client-type_header">
                                            <Typography className="type" variant="h5" component="h3">
                                                On Site Event
                                            </Typography>
                                            <Typography  className="description" variant="h4" component="h4">
                                                <span>Starting from</span>
                                                <strong> $275</strong>
                                            </Typography>
                                        </Box>
                                        <Box className="client-type_body">
                                            <Typography  >
                                                Events such as weddings, birthday parties, baby showers, bridal showers, who are looking to book for a couple hours.
                                            </Typography>
                                        </Box>
                                        
                                        <Button fullWidth={true} variant="contained" size="large" color="primary" onClick={ e => {this.props.history.push({pathname:`/booking`})}}>
                                            Book Now
                                        </Button>
                                </Box>
                            </Grid>
                           
                            <Grid item xs={12} md={4}>
                                <Box className={`client-type ${corporate}`} onClick={this.handleTypeSelection.bind(this, "corporate")}>
                                        <Box className="client-type_header">
                                            <Typography className="type" variant="h5" component="h3">
                                                Corporate Event
                                            </Typography>
                                            <Typography  className="description" variant="h4" component="h4">
                                                Contact us for quote
                                            </Typography>
                                        </Box>
                                        <Box className="client-type_body">
                                            <Typography  >
                                                Is this an event for your company, launch parties, seminars, charity, or networking?
                                            </Typography>
                                        </Box>
                                        <Button fullWidth={true} variant="contained" size="large" color="primary" onClick={this.handleCorporateQuote.bind()}>
                                        {/* <Button fullWidth={true} variant="contained" size="large" color="primary" > */}
                                            Get Quote
                                        </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>

                {this.renderPersonalContent()}
                {this.renderVirtualBooth()}
                {this.renderCorporateContent()}
                

                
            </Box>

            
            <CommonFollowUs/>
            </>
        )
    }
}