import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from "@material-ui/core/Container"
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// import BGLines from '../Common/common.bg-lines'

import './Contact.scss';


export default class Contact extends Component {

    constructor(props) {
        super(props);

        this.state = {
           
        }
    }

    componentDidMount = () => {
        // append class
        document.getElementsByTagName("BODY")[0].className="p-contact"
    }
    
    componentWillUnmount = () =>{
        // remove class
        document.getElementsByTagName("BODY")[0].className=""
    }

    render() {
        return  (
            <>
            <Box component="div" className="page-hero --sub-page --center"> 
                <Container component="main" maxWidth="lg" fixed >
                    {/* <Typography variant="h5" component="h1">
                        Contact
                    </Typography> */}
                    <Typography variant="h1" component="h1" className="--icon">
                        Contact Us
                    </Typography>
                </Container>
            </Box>
            <Box component="main" >
            {/* <Box component="main" className="--overlap-effect"> */}
                <Container maxWidth="lg" fixed>

                <Box as="section" className="faq">
                    <Box className="header_sub-title">
                        <Typography variant="h4" component="h2" >
                                Frequently asked questions
                        </Typography>
                        {/* <Typography variant="h6" component="h5">
                            You can email as at <a href="mailto:hello@brstory.ca">hello@brstory.ca</a>
                        </Typography> */}
                    </Box>


                    <Box className="accordion">
                        <ExpansionPanel>
                            <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="faq1-content"
                            id="faq1-header"
                            >
                            <Typography  variant="h6" component="h3">What does it mean by “unlimited printing”?</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography>
                                    One print for each guest in the photo. So if there are three people in the picture, each guest in the photo can take a print copy. 
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Box>
                    

                    <Box className="accordion">
                        <ExpansionPanel>
                            <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="faq2-content"
                            id="faq2-header"
                            >
                                <Typography variant="h6" component="h3">Can I make a custom template?</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography>
                                    Yes! We will incorporate your text, layout and vision of your design. We will provide you with two mock ups to choose from. 
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Box>

                    <Box className="accordion">
                     
                        <ExpansionPanel>
                            <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="faq3-content"
                            id="faq3-header"
                            >
                                <Typography variant="h6" component="h3">What are the requirements for photobooth set up?</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography>
                                    We would appreciate it if your event has a space of 8 feet by 8 feet footprint with a power outlet within 10 feet. This will provide us with enough space for our equipment, backdrop and props without getting in the way of your guests. 
                                    The space will also allow more guests to fit into the picture.
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Box>

                    <Box className="accordion">
                        <ExpansionPanel>
                            <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="faq4-content"
                            id="faq4-header"
                            >
                                <Typography variant="h6" component="h3">How long does it take to print the pictures?</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography>
                                    The pictures will be ready for you on the spot!
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Box>

                    <Box className="accordion">
                        <ExpansionPanel>
                            <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="faq5-content"
                            id="faq5-header"
                            >
                                <Typography variant="h6" component="h3">What sizes are the photos?</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography>
                                    Our unlimited print strips are 2x6 inches. You can change the photo size by upgrading to 4x6 inches with additional cost.
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Box>


                </Box>
                <Box as="section" className="email-us">
                    <Box className="header_sub-title">
                        <Typography variant="h4" component="h2" >
                                Do you have more questions?
                        </Typography>
                        <Typography variant="h6" component="h5">
                            You can email as at <a href="mailto:hello@brstory.ca">hello@brstory.ca</a>
                        </Typography>
                    </Box>
                </Box>
                </Container>
            </Box>
            </>
        )
    }
}