import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// components
import ModalSuccess from '../Common/ModalSuccess'
import SectionStart from './Booking/Start'
import SectionClientInfo from './Booking/ClientInfo'
import SectionEventInfo from './Booking/EventInfo'
import SectionAdditionalInfo from './Booking/AdditionalFlow'

import SectionPackageCustomization from './Booking/PackageCustomization'

import * as FullStory from '@fullstory/browser';

import './LayoutBooking.scss';

const steps = [
    "customization",
    "event-info",
    // "additional-info",
    "client-info",
]

export default class LayoutBooking extends Component {

    constructor(props) {
        super(props);

        this.state = {
            inProgress: false,
            isDisabled: false,
            modal: {
                show: false, 
                type: "success",
                content: {
                    title: "Placeholder!",
                    body: "You will be hearing from us soon.",
                    footer: 
                        <Button onClick={this.handleModalClose}  variant="contained" size="large" color="primary" fullWidth={true}  autoFocus>
                            Close
                        </Button>
                }
            },
            selectedBackdrop : null,
            selectedEventType: null,
            currentStep : 0
        }
    }

    componentDidMount = () => {
        document.getElementsByTagName("BODY")[0].className="p-booking"
        if (this.props.backdrops.length === 0){
            this.props.get("backdrops", "").then(responses => {
                this.props.handleGlobalStateChange({backdrops: responses})
             
            })
        }
    }
    
    componentWillUnmount= () =>{
        // remove class
        document.getElementsByTagName("BODY")[0].className=""
    }
    
    
    renderModal = (type, message) => {
        if(type === "success") {
            this.setState({
                modal: {
                    show: true,
                    type:type,
                    content: {
                        title: "Thank you!",
                        body: "You will be hearing from us soon.",
                        footer: 
                            <Button onClick={this.handleModalClose} fullWidth={true} color="primary"  variant="contained"  autoFocus>
                                Close
                            </Button>
                    }
                }
            })
        }
    };

    handleBookingTotalCost = () => {

    }
    
    handleModalClose = () => {
        this.setState({
            modal: {
                show: false, 
                type: "",
                content: {
                    title: "",
                    body: "",
                    footer: ""
                }
            }
        })
    };

    handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation(); 

        this.setState({
            isDisabled: true
        })

        let booking = this.props.booking

            if (this.props.booking.event_type === "other") {
                booking.event_type = this.props.booking.event_type_custom
            }
            
            // REMOVE COMMENT WHEN REDADY TO SUBMIT
            this.props.put("bookings", booking).then(responses => {
                if (responses.message === "booked") {
                    this.renderModal("success","Booking request successful")
                    FullStory.identify(`${responses.result._id}`, {
                        "displayName": `${responses.result.first_name} ${responses.result.last_name}`, 
                        "email": `${responses.result.email}`
                    });
                    FullStory.setUserVars({
                        "displayName": `${responses.result.first_name} ${responses.result.last_name}`, 
                        "email": `${responses.result.email}`,
                        "packageHour": `${responses.result.package.hour}`,
                        "packageBackdrop": `${responses.result.package.backdrop}`,
                    });
                    FullStory.log('Customer Booked successfully');
                } else {
                    FullStory.log('error', 'Booking Failed');
                }
            })
    }

    handleNext = (value) => {
        let step = value + 1
        
        this.refs[step].scrollIntoView({ behavior: 'smooth', block: "start", inline:"start"});
        
        this.setState({
            currentStep : step
        })

    }

    renderSteps = () => {
        let output  = [] 
            
            steps.forEach(function (step, i) {
                if (step === "start") {
                    output.push(
                         <Box ref={i} key={i} component="section" className="start">
                            <Container ref={i} key={i} component="section" maxWidth="lg" className={`f_anchor ${step}`} fixed>
                                    <SectionStart 
                                        {...this.state} 
                                        idx={i}
                                        handleNext={this.handleNext}
                                        handleInputChange={this.handleInputChange} 
                                    />
                            </Container>
                        </Box>
                    )
                } 
                else if(step === "client-info"  && this.props.booking.event_type !== "corporate") {
                    output.push(
                        <Container ref={i} key={i} component="section" maxWidth="lg" className={`f_anchor ${step}`} fixed>
                            <SectionClientInfo 
                                {...this.props} 
                                {...this.state} 
                                idx={i}
                                handleNext={this.handleNext}
                                handleInputChange={this.handleInputChange} 
                            />
                        </Container>
                    )
                } else if(step === "customization" ) {
                    output.push(
                        <Container ref={i} key={i} component="section" maxWidth="lg" className={`f_anchor ${step}`} fixed>
                            
                            <SectionPackageCustomization 
                                idx={i}
                                {...this.props}
                                {...this.state} 
                                handleNext={this.handleNext}
                                handleOpenDropdownMenu={this.handleOpenDropdownMenu} 
                                handleCloseDropdownMenu={this.handleCloseDropdownMenu} 
                                handleInputChange={this.handleInputChange} 
                                handleAddOnChange={this.handleAddOnChange}
                            />
                            
                        </Container>
                    )

                } else if(step === "event-info" && this.props.booking.event_type !== "corporate") {
                    output.push(
                        <Container ref={i} key={i} component="section" maxWidth="lg" className={`f_anchor ${step}`} fixed>
                            
                            <SectionEventInfo 
                                {...this.props}
                                {...this.state} 
                                idx={i}
                                handleNext={this.handleNext}
                                handleInputChange={this.handleInputChange} 
                                handleCustomInputChange={this.handleCustomInputChange} 
                                handleDateTimeChange={this.handleDateTimeChange} 
                            />
                        </Container>
                    )
                } else if(step === "additional-info" && this.props.booking.event_type !== "corporate") {   
                    output.push(
                        <Container ref={i} key={i} component="section" maxWidth="lg" className={`f_anchor ${step}`} fixed>
                            
                            <SectionAdditionalInfo 
                                {...this.props}
                                {...this.state} 
                                idx={i}
                                handleSubmit={this.handleSubmit}
                                handleInputChange={this.handleInputChange}  
                            />
                        </Container>
                    )
                }
            }.bind(this));  

            return output
    }

    handleCloseDropdownMenu = (type, value) => {
        this.setState({
            [type] : null
        }, () => {
            if (value !== null) {
                this.props.handleUpdateBooking(type, value) 
            }
            
        })
    }

    handleOpenDropdownMenu = (e) => {
        this.setState({
            [e.currentTarget.id] : e.currentTarget
        })
    }

    handleDateTimeChange = (date) => {
        this.props.handleUpdateBooking("event_date", date) 
    }

    handleCustomInputChange = (key, value) => {
        this.setState({
            booking : {
                ...this.state.booking,
                [key]: value
            }
        })
    }

    handleCheckboxChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let lst = [];  
        
        if (e.target.name === "package_add_on") {
            let addOns = this.state.booking.package_add_on
            if(!addOns.includes(value)) {
                addOns.push(value)
            } else {
                addOns.splice(addOns.indexOf(value), 1);
            }

            lst = addOns
        }

        this.setState({
            booking : {
                ...this.state.booking,
                [name]: lst
            }
        })
    }

    handleAddOnChange = (e) => {
        let title = e.target.name;
        let price = e.target.value;
        let addOns = this.props.booking.package_add_on
        

        if (this.props.booking.package_add_on.length === 0) {
            addOns.push({title:title, price:price})
        } else {
            
            if(addOns.filter(function(e) { return e.title === title; }).length > 0) {
                const index = addOns.findIndex(obj => obj.title === title);
                addOns = [
                    ...addOns.slice(0, index),
                    ...addOns.slice(index + 1)
                ]
            } else { 
                addOns.push({title:title, price:price})
            }
        }
      
        this.props.handleUpdateBooking("package_add_on", addOns) 
    }

    handleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value; 


        this.props.handleUpdateBooking(name, value)


    }

    renderAddOns() {
        let output = []
    
            this.props.booking.package_add_on.forEach(function (product, i) {
                output.push(
                    <Box className="item" key={i}>
                        <Typography  variant="caption" display="block" gutterBottom>
                            {product.title}
                        </Typography>
                        <Typography  variant="caption" display="block" gutterBottom>
                            ${product.price}
                        </Typography>
                    </Box>
                )
                
            })
        
            return output
    }

    renderBackdropSelection() {

        let output = null

            if(this.props.booking.package_backdrop !== "") {
                output = 
                <Box className="item">
                    <Typography  variant="caption" display="block" gutterBottom>
                        BD : {this.props.booking.package_backdrop}
                    </Typography>
                    <Typography  variant="caption" display="block" gutterBottom>
                        Included
                    </Typography>
                </Box>
            }

            return output
        
    }

    renderGuestNumber() {
        let output = ""

            if (this.props.booking.event_guest_number > 2) {
                output = 
                <Box className="item">
                    <Typography  variant="caption" display="block" gutterBottom>
                    {this.props.booking.event_guest_number} guests
                    </Typography>
                    <Typography  variant="caption" display="block" gutterBottom>
                        No additional cost
                    </Typography>
                </Box>
            }
            return output                                            
    }

    renderVenue() {
        let output = ""

            if(this.props.booking.event_venue_name !== "" || this.props.booking.event_venue_address !== "" ) {
                output = 
                <Box className="total-cost_breakdown --venue">
                    {/* <Typography  variant="body2" display="block" gutterBottom className="total-cost_label">
                        Event Detail
                    </Typography>   */}
                    <Box className="item">
                        <Typography  variant="caption" display="block" className="--venue-address" gutterBottom>
                            <strong>{this.props.booking.event_venue_name}</strong><br/>
                            {this.props.booking.event_venue_address}
                        </Typography>
                    
                    </Box>
                    <Box className="item">
                        <Typography  variant="caption" display="block" className="--venue-time" gutterBottom>
                            {this.props.booking.event_day} , {this.props.booking.event_start_time} - {this.props.booking.event_end_time}
                        </Typography>
                    </Box>
                </Box>
            }
        return output
    }

    handleCorporateQuote = () => {
        window.location.href = "mailto:hello@brstory.ca?subject=Corporate Event Quote&body=Hello, please answer the questions below and we'll get back to your shortly%0A%0ACoporate Name:%0AApproximately number of guests:%0AEvent Date and Time:%0ALocation:";
    }

    handleAddOnTotalSum = ( addOn) => {
        let totalValue = 0 

        if (addOn.length > 0) {
           totalValue = addOn.reduce((accum,item) => parseInt(accum) + parseInt(item.price) , 0)
        } else {
            totalValue = 0
        }

        return totalValue

    }
    

    renderBookingSummary() {
        let output = ""
        // let disableSubmit = true
        let totalCost = parseInt(this.props.booking.package_price)  + this.handleAddOnTotalSum(this.props.booking.package_add_on)
        let defaultService = ""

            if (this.props.booking.event_service === "on-site") {
                defaultService = 
                <Box className="item">
                    <Typography  variant="caption" display="block" gutterBottom>
                    {this.props.booking.package_hour} Hr
                    </Typography>
                    <Typography  variant="caption" display="block" gutterBottom>
                        ${this.props.booking.package_price}
                    </Typography>
                </Box>
            } else {
                defaultService = 
                <Box className="item">
                    <Typography  variant="caption" display="block" gutterBottom>
                        Full Day
                    </Typography>
                    <Typography  variant="caption" display="block" gutterBottom>
                        ${this.props.virtualBooth.price}
                    </Typography>
                </Box>
            }

            // if(this.props.booking.client_email !== "") {
            //     disableSubmit = false
            // }

            if(this.props.booking.event_type === "corporate"){
                output = 
                <>
                    <Box className="total-cost_price">
                        <Typography  variant="h3" component="h4">
                            Contact us
                        </Typography>
               
                    </Box>
                    <Box className="client-type_body">
                        <Typography  >
                            Coporate events are customized on a base by base event. 
                        </Typography>
                    </Box>
                    <Button  onClick={this.handleCorporateQuote.bind()} fullWidth={true} variant="contained" size="large" color="primary">
                        Get Quote
                    </Button>
                </>

            } else {
                output = 
                <>
                        <Box>
                            <Box className="total-cost_servive">
                                <Typography  variant="h3" component="h3">
                                    {this.props.booking.event_service.replace("-", " ")}
                                </Typography>
                              
                            </Box>
                            <Box className="total-cost_price">
                                <Typography  variant="h3" component="h4">
                                    ${totalCost}
                                </Typography>
                                <p>Total Price</p>
                            </Box>
                            <Box className="total-cost_breakdown --package">
                                <Typography  variant="body2" display="block" gutterBottom className="total-cost_label">
                                    Package
                                </Typography>
                                {defaultService}
                                
                                {this.renderBackdropSelection()}
                                {this.renderAddOns()}
                                {this.renderGuestNumber()}
                            </Box>
                            {this.renderVenue()}
                        </Box>

                        {/* <Button type="submit" disabled={disableSubmit || this.state.isDisabled} fullWidth={true} variant="contained" size="large"  color="primary">
                            Book Now
                        </Button> */}

                         <Button type="submit" disabled={true} fullWidth={true} variant="contained" size="large"  color="primary">
                            Book Now
                        </Button>
                
                </>    
            }
        
        return output
    }

    render() {
        let modal = <ModalSuccess {...this.state} handleModalClose={this.handleModalClose}/>
        return (
            <>
            <Box component="main">
                <Box component="section" className="start">
                    <Container fixed>
                        <SectionStart 
                            {...this.state} 
                        
                            handleNext={this.handleNext}
                            handleInputChange={this.handleInputChange} 
                        /> 
                        {/* <Shape/> */}
                    </Container>
                </Box>
                <Container fixed>
                {/* <BGLines/> */}
        
                    <form  onSubmit={this.handleSubmit} autoComplete="off">
                        <Grid  container >
                            <Grid item xs={12} md={8}>
                    
                                
                                    {this.renderSteps()}
                                
                            </Grid>
                            <Grid item xs={12} md={4}>  
                                <Box className="package_total-cost package_base-price">
                                    {this.renderBookingSummary()}
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                </Container>
                
                {/* Delete after this */}
{/*                 
                <form  onSubmit={this.handleSubmit}  autoComplete="off">
                    {this.renderSteps()}
                    <Container component="section"  className="f_navigation">
                        <Button variant="contained" color="primary" size="large" type="submit" >
                            Submit
                        </Button>
                    </Container>
                </form> */}
            </Box>
            {modal}
          </>
        );
    }

}