import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid"
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';


import './../LayoutBooking.scss';

export default class LayoutBookingClientInfo extends Component {
    render() {
        return (
            <React.Fragment>
                <Grid  container spacing={3} className="f_client-information">
                    <Grid item xs={12} md={12}>
                        <Box className="f_header">
                        <Typography variant="h4" component="h3">
                            <span className="f_header_step">{this.props.idx}.</span>The Last Step.
                        </Typography>
                        <Typography >
                           A little bit of information about you so we can reach out to you.
                        </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        {/* <Typography  variant="body2"  component="h5" className="f_header-subtitle">
                            Quick information about  
                        </Typography> */}
                        <Grid  component="section" container  spacing={3} >
                            <Grid item xs={12} md={6}>
                                <TextField 
                                    required 
                                    defaultValue={this.props.booking.client_first_name} 
                                    id="client_first_name" 
                                    name="client_first_name" 
                                    label="First Name" 
                                    variant="outlined" 
                                    disabled={this.props.isDisabled}
                                    onChange={this.props.handleInputChange} 
                                    fullWidth 
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField 
                                    required 
                                    defaultValue={this.props.booking.client_last_name} 
                                    id="client_last_name" 
                                    name="client_last_name" 
                                    label="Last Name"  
                                    variant="outlined"  
                                    disabled={this.props.isDisabled}
                                    onChange={this.props.handleInputChange} 
                                    fullWidth 
                                />
                            </Grid>
                                
                        </Grid>
                        <Grid component="section" container  spacing={3} >
                            <Grid item xs={12}>
                                <TextField 
                                    required 
                                    defaultValue={this.props.booking.client_email} 
                                    type="email" 
                                    id="client_email" 
                                    name="client_email" 
                                    label="Email"  
                                    variant="outlined" 
                                    disabled={this.props.isDisabled}
                                    onChange={this.props.handleInputChange} 
                                    fullWidth 
                                />
                            </Grid>
                        </Grid>
                        <Grid component="section" container  spacing={3} >
                            <Grid item xs={12}>
                                <TextField 
                                    id="additional_questions" 
                                    name="additional_questions" 
                                    label="Additional Questions or comments ?"  
                                    placeholder="Questions, comments, promo code"
                                    multiline={true}
                                    defaultValue={this.props.booking.additional_questions} 
                                    rows={4}
                                    rowsMax={4}
                                    variant="outlined"  
                                    disabled={this.props.isDisabled}
                                    onChange={this.props.handleInputChange} 
                                    fullWidth 
                                />
                            </Grid>
                        </Grid>
                    
                    </Grid>
                    
                    
                </Grid>

                {/* <Box className="f_navigation">
                    <Button variant="contained" color="primary" size="large" onClick={this.props.handleNext.bind(this, this.props.idx)}>
                        Next
                    </Button>
                </Box> */}
            </React.Fragment>
        );
    }
}