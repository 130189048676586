import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import './Modal.scss';

export default class CommongModalSuccess extends Component {

    render() {
        return (
            <>
                <Dialog
                    maxWidth="md"
                    open={this.props.modal.show}
                    className="modal --success"
                    onClose={this.props.handleModalClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.props.modal.content.title}</DialogTitle>
                    <DialogContent>
                        {this.props.modal.content.body}
                    </DialogContent>
                    <DialogActions>
                        {this.props.modal.content.footer}
                    </DialogActions>
                </Dialog>
            </>
        );
    }

}