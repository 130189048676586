import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import './Common.scss';

export default class CommonFooter extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        return (
            <Box component="footer" >
                <Container >
                <Typography variant="caption" component="h6">
                    @Copyright 2020 BRSTORY                             
                </Typography>
                </Container>
            </Box>
        );
    }

}