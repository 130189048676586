import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';

import './Service.scss';

const features = [
    {
        title: "Green Screen",
        img : "assets/img/vb_features_green-screen_small.gif"
    },
    {
        title: "Filters",
        img : "assets/img/vb_features-filters_small.gif"
    },
     {
        title: "Stickers",
        img : "assets/img/vb_features_stickers_large.gif"
    }, 
    {
        title: "Social Sharing",
        img : "assets/img/vb_features_social-sharing.jpg"
    },
    {
        title: "Gallery",
        img : "assets/img/vb_features_gallery.gif"
    },
    {
        title: "Compatible on Desktop, tablet and smartphone",
        img : "assets/img/vb_multiple-device.png"
    }
]
export default class ServiceVirtualBooth extends Component {

    constructor(props) {
        super(props);

        this.state = {
            features: features
        }
    }

    componentDidMount = () => {
        document.getElementsByTagName("BODY")[0].className="p-virtual-booth"
    }
    
    componentWillUnmount = () =>{
        // remove class
        document.getElementsByTagName("BODY")[0].className=""
    }

    renderFeatures  = () => {
        let output = []
        // let imgURL = "assets/img/backdrop"
        
        this.state.features.forEach(function (feature, i) {
            output.push(
                <Grid item xs={12} md={6} lg={4} key={i}>
                    <Card elevation={0} className="item"> 
                             <Box className="img-wrapper">
                                <CardMedia
                                    component="img"
                                    alt={feature.name}
                                    height="140"
                                    image={`${feature.img}`}
                                    title={feature.title}
                                />
                                </Box>
                            <CardContent>
                            <Typography align="center" gutterBottom variant="h6" component="h3">
                                {feature.title}
                            </Typography>
                            {/* <Typography align="center" variant="body1" color="textSecondary" component="p">
                                {feature.type}
                            </Typography> */}
                            </CardContent>
                    </Card>
                </Grid>
            )
        })

        return output
    }

    handleGotoBooking = () => {
        this.props.history.push({
            pathname:`/booking`
        },this.props.handleUpdateBooking("event_service", "virtual-booth"))
    }

    render() {
        return  (
            <>
        
            <Box component="div" className="page-hero --sub-page --center"> 
                {/* <CommonBGLines/> */}
                <Container component="div" maxWidth="lg" fixed >
                    {/* <Typography variant="h5" component="h1">
                        Backdrops
                    </Typography> */}
                    <Typography variant="h1" component="h1" className="--icon">
                        Virtual Booth
                    </Typography> 
                    <Container component="div" maxWidth="md" fixed >
                        <Typography className="lead" align="center" variant="h5" color="textSecondary" component="p" gutterBottom>
                            Engage your virtual audience, create social content, and give people a reason to smile!
                        </Typography>
                        <Typography align="center" color="textSecondary" component="p" className="text-color-primary">
                            <strong>No App Needed + Branded Interface</strong>
                        </Typography>     
                    </Container>            
                </Container>
            </Box>
            <Box component="main" className="--overlap-effect">
                <Container component="section" maxWidth="lg" fixed>  

                    <Box className="vb-device">
                            <img className="img" src="assets/img/vb_hero-center.png" alt="Virtual Booth example 1"/>
                    </Box>
                    <Box className="vb-hero-image">
                        <Box className="vb-hero-image_example --left">
                            <img className="img" src="assets/img/vb_hero-left.png" alt="Virtual Booth example greenscreen"/>
                     
                        </Box>
                        <Box className="vb-hero-image_example --right">
                            <img className="img" src="assets/img/vb_hero-right.png" alt="Virtual Booth example 2"/>
                        </Box>
                    </Box>
                </Container>
                <Container component="section" maxWidth="md" fixed>  
                    <Typography align="center" variant="h4"  gutterBottom>
                        <strong>Virtual Booth is perfect all kinds of events. Perfect for: weddings, online conferences, birthdays & more</strong>
                    </Typography>
                </Container>
                <Container  component="section" className="vb-multiple-device " fixed>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={7} className="content-img">
                            <img className="img" src="assets/img/vb_multiple-device.png" alt="multiple devices"/>
                        </Grid>

                        <Grid item xs={12} md={5} className="content-copy">
                    
                            <Typography variant="p" component="h2" gutterBottom>
                                Works in any broswer, on any device
                            </Typography> 
                            <Typography className="lead" component="p" gutterBottom>
                                It’s seamlessly simple. Your virtual photo booth starts with just one click and doesn’t require any additional software to run. It works with your phone, tablet or laptop.
                            </Typography>
                            {/* <Button  className="btn_book-now --desktop " variant="contained" size="large"  color="primary"  onClick={this.handleGotoBooking.bind()}>
                                Demo
                            </Button>
                           */}
                        </Grid>
                    </Grid>
                </Container>

                
                <Box component="section" className="overlap-effect">
                    <Container>
                        <Box className="header_sub-title">
                            <Typography variant="h3" component="h2" >
                                    Popular Virtual Booth Features
                            </Typography>
                        
                        </Box>
                        <Grid  container spacing={3} className="vb-features" >
                            {this.renderFeatures()}
                        </Grid>
                    </Container>

                    <Container component="section" maxWidth="md" className="vb-cta" fixed>  
                        <Typography align="center" variant="h4"  gutterBottom>
                            <strong>Enhance your virtual event and engage your attendees with virtual booth.</strong>
                        </Typography>
                        <Button  className="btn_book-now --desktop " variant="contained" size="large"  color="primary"  onClick={this.handleGotoBooking.bind()}>
                            Book Today
                        </Button>
                    </Container>
                </Box>
            </Box>
            </>
        )
    }
}