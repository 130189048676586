import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';


import './Common.scss';

export default class CommonNewsletter extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        return (
            <Box className="newsletter" component="section">
                <Container  fixed>
                    <Box className="header_sub-title">
                            <Typography variant="h3" component="h2" className="newsletter_title">
                                    Enjoy $25 off your first booking!
                            </Typography>
                            <Typography variant="h6" component="h6" className="newsletter_description">
                                    Simply just follow us @brstory.photobooth and subscribe to our newsletters
                            </Typography>
                            <Box className="form">
                                <form  noValidate autoComplete="off" block="true">
                                    <TextField id="outlined-basic" label="Email" variant="outlined" />
                                    <Button type="submit" variant="contained" size="large"  color="primary" className="--solid">
                                        Submit
                                    </Button>
                                </form>
                            </Box>
                            <Typography variant="caption" className="form_disclaimer">
                                  We respect your privacy
                            </Typography>
                 
                        </Box> 
                </Container>
            </Box>
        );
    }

}