import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import './Common.scss';

export default class CommonNewsletter extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        return (
            <Box className="newsletter follow-us" component="section">
                <Container  fixed>
                    <Box className="header_sub-title">
                            <Typography variant="h3" component="h2" className="follow-us_title">
                                    Want to see more ? 
                            </Typography>
                            <Typography variant="h6" component="h6" className="follow-us_description">
                                    <span className="mobile-break">Follow us at @brstory.photobooth on</span> <a href="https://www.instagram.com/brstory.photobooth"  target="_blank" rel="noopener noreferrer" className="instagram">Instagram</a> and <a href="https://www.facebook.com/brstory.photobooth"  target="_blank" rel="noopener noreferrer" className="facebook">Facebook</a>
                            </Typography>
                            {/* <Box className="form">
                                <form  noValidate autoComplete="off" block="true">
                                    <TextField id="outlined-basic" label="Email" variant="outlined" />
                                    <Button type="submit" variant="contained" size="large"  color="primary" className="--solid">
                                        Submit
                                    </Button>
                                </form>
                            </Box>
                            <Typography variant="caption" className="form_disclaimer">
                                  We respect your privacy
                            </Typography> */}
                 
                        </Box> 
                </Container>
            </Box>
        );
    }

}