import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';

import Logo from './Logo'

// import MenuIcon from '@material-ui/icons/Menu';


import './Common.scss';

const links=[
    {name: "Home", route:"/home"},
    {name:"On-Site Booth", route: "/photobooth"},
    {name: "Pricing", route:"/pricing"},
    {name: "Backdrops", route:"/backdrops"},
    {name: "Albums", route:"/albums"},
    {name: "Contact", route:"/contact"}
    // {name:"Virtual Booth", route:"/virtual-booth"}
]




export default class CommonNavbar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            page : "p-" + this.props.history.pathname.replace("/", ''),
            mobileMenuOpen: false,
            top: false
        }
    }

    componentDidMount = () => {
        // append class
            document.getElementsByTagName("BODY")[0].className=this.state.page
    }
    
    componentWillUnmount= () =>{
        // remove class
        document.getElementsByTagName("BODY")[0].className=""
    }

    handleClick = (value) => {
        // console.log(this.props)
        // this.props.history.push('/' + value);
    }
    
    handleToHome = () => {
        console.log(this.props)
        // window.location.replace("/");
        window.location.replace("/");
    }


    renderMobileNav() {
        // console.log(this.props)
        return (
            <Drawer anchor="top" open={this.state["mobileMenuOpen"]} onClose={this.toggleDrawer(false)} className="header_mobile-nav">
                <div
                    role="presentation"
                    onClick={this.toggleDrawer(false)}
                    onKeyDown={this.toggleDrawer(false)}
                    >
                    <List className="header_mobile-nav-list">
                        {links.map((link) => (
                            <ListItem button key={link.name}>
                                <NavLink className="MuiLink-root" to={`${link.route}`}>{link.name}</NavLink>   
                            </ListItem>
                        ))}  
                        
                        <ListItem className="booking">
                            <NavLink className="MuiLink-root" to={`/booking`}>Book Now</NavLink>      
                            {/* <Button variant="contained"  color="primary" size="small"  fullWidth disableElevation>
                                Book Now
                            </Button> */}
                        </ListItem>
                       
                    </List>
                    </div>
            </Drawer>
        )
    }

    renderDesktopNav() {
        return (
            <Box className="header_links">
                {links.map((link) => (
                    <NavLink key={link.name} className="MuiLink-root" to={`${link.route}`}>{link.name}</NavLink>   
                ))}  
            </Box>
        )
    }


    toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        this.setState(
            { mobileMenuOpen: open }
        );
    };

    render() {
        let className = ""
        let nav = ""
        let navPosition = ""
        let tweenTo = ""
        let theme = ""
            

            if (this.props.history.pathname.includes("/home") || this.props.history.pathname.includes("/client")) {
                theme = "--dark"
            } else {
                theme = " --light"
            }

            if(this.props.mobile) {
                nav = this.renderMobileNav()
                navPosition = "relative"
                className="mobile-nav"
                tweenTo = {
                            left: '0px',
                            backgroundColor: "transparent"
                        }
            }else {
                nav = this.renderDesktopNav()
                navPosition = "fixed"
                tweenTo = {
                                className:`${theme} --scrolled`
                            }
    
            }

            if (this.state.mobileMenuOpen) {
                className = "--mobile-open"
            } 

                        
            
            

        return (
            <React.Fragment>
                <Controller>
                    <Scene duration={100} triggerHook={0}  triggerElement="#" indicators={false}>
                        {(progress, event) => (
                            <Tween            
                                to={tweenTo}       
                                ease="ease-in"
                                totalProgress={progress}
                                paused

                            >
                            <AppBar elevation={0} className={`tween ${theme} ${className} `} position={navPosition} as="nav" color={"default"}>
                                
                                <Box >
                                    <Box className="left">
                                        <Box onClick={this.handleToHome} className="brstory-monogram"></Box><Logo/>
                                    </Box>
                                    <Container className="middle" component="div" fixed>  
                                        <Toolbar>
                                            <Box className="menu-links">
                                                {/* <Typography variant="h6" className={`header_logo ${this.state.mobileMenuOpen ? "--mobile-open" : null}`}>
                                                    <Link className="MuiLink-root" to="/home" >
                                                        <Logo/>
                                                    </Link>
                                                </Typography> */}
                                                 <Box onClick={this.handleToHome} className="brstory-monogram"></Box>   
                                                {nav}
                                            </Box>
                                            <IconButton edge="start"  color="inherit" aria-label="menu" className="header_mobile-nav-icon" onClick={this.toggleDrawer((this.state.mobileMenuOpen ? false : true))}>
                                                <div className={ `menu-icon ${this.state.mobileMenuOpen ? "open" : ""}`}></div>
                                                {/* <MenuIcon /> */}
                                                
                                            </IconButton>
                                            
                                           
                                        </Toolbar>
                                        
                                    </Container>
                                    <Box className="right">
                                        <NavLink className="MuiLink-root book-now --desktop" to="/booking"  >
                                                <Button variant="contained" color="primary"  >
                                                    Book Now
                                                </Button>
                                        </NavLink>
                                    </Box>
                                </Box>
                            </AppBar>
                            </Tween>    
                        )}
                    </Scene>

            
                </Controller>
            </React.Fragment>
        );
    }

}