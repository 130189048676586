import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import CommonShape from '../Common/DividerShape'
import CommonNewsletter from '../Common/Newsletter'
import CommonBGLines from '../Common/BGLines'

import './Template.scss';

export default class Templates extends Component {

    constructor(props) {
        super(props);

        this.state = {
            templates: this.props.templates
        }
    }

    componentDidMount = () => {
        // append class
        document.getElementsByTagName("BODY")[0].className="p-templates"
        if (this.state.templates.length < 1){
            this.props.get("templates", "").then(responses => {
                
                this.setState({
                    templates: responses
                })
            })
        }
    }
    
    componentWillUnmount = () =>{
        // remove class
        document.getElementsByTagName("BODY")[0].className=""
    }


    renderTemplates  = () => {
        let output = []
        let imgURL = "https://storage.cloud.google.com/www.brstory.ca/assets/img/template"
        
        this.state.templates.forEach(function (template, i) {
            output.push(
                // <Grid item xs={12} md={6} lg={3} key={i}>
                //     <Card elevation={0} className="item"> 
                //             <Box className="MediaImage">
                //                 <CardMedia
                //                     component="img"
                //                     alt={backdrop.name}
                //                     image={`https://storage.googleapis.com/www.brstory.ca/assets/img/template/TPC1.png?v=3`}
                //                     title={backdrop.name}
                //                 />
                //                 <img className="MediaImage-placeholder" src="https://storage.googleapis.com/www.brstory.ca/assets/img/template/template_postcard-placeholder.png?v2" />
                //             </Box>
                            
                //             <CardContent>
                //             <Typography align="center" gutterBottom variant="h6" component="h3">
                //                 {backdrop.name}
                //             </Typography>
                //             <Typography align="center" variant="body1" color="textSecondary" component="p">
                //                 {backdrop.type}
                //             </Typography>
                //             </CardContent>
                //     </Card>
                // </Grid>

                <Grid item xs={12} md={6} lg={3} key={i}>
                <Card elevation={0} className="item"> 
                        <Box className="MediaImage">
                            <CardMedia
                                component="img"
                                alt={template.name}
                                image={`${imgURL}/${template.code}.png?v=1`}
                                // image={`https://storage.googleapis.com/www.brstory.ca/assets/img/template/TFS17.png?v=3`}
                                title={template.name}
                            />
                            {/* <img className="MediaImage-placeholder" alt="template" src="https://storage.googleapis.com/www.brstory.ca/assets/img/template/template_film-strip-placeholder.png?v4" /> */}
                        </Box>
                        
                        <CardContent>
                        <Typography align="center" gutterBottom variant="h6" component="h3">
                            {template.name}
                        </Typography>
                        <Typography align="center" variant="body1" color="textSecondary" component="p">
                            {template.type}
                        </Typography>
                        </CardContent>
                </Card>
                </Grid>
            )
        })

        return output
    }

    render() {
        console.log(this.props)
        return  (
            <>
            <Box component="div" className="page-hero --sub-page"> 
                <CommonBGLines/>
                <Container component="div" maxWidth="lg" fixed >
                    {/* <Typography variant="h5" component="h1">
                        Templates
                    </Typography> */}
                    <Typography variant="h1" component="h1">
                    Templates
                    </Typography> 
                </Container>
            </Box>
            <Box component="main" className="--overlap-effect">
                <CommonShape/>
                <Container component="section" maxWidth="lg" fixed>  
                    <Grid  container spacing={3} className="product-gallery templates --film-strip">
                        {this.renderTemplates()}
                    </Grid>
                </Container>
                <CommonNewsletter/>
            </Box>
            </>
        )
    }
}