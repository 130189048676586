import React, { Component } from 'react';
import Box from '@material-ui/core/Box'

import './Common.scss';

export default class CommonLogo extends Component {
    render() {
        return (
            <React.Fragment>
                <Box className="logo --white"> 
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 909.98 123.71">
                        <path d="M0,2.09H70.18c24.84,0,42.1,12.7,42.1,30.1,0,11.66-6.64,21.75-18,26.79,15.18,5.92,24.09,17.23,24.09,30.45,0,19.14-19,32.19-45.52,32.19H0ZM61.26,48.54c7.59,0,13.09-3.82,13.09-9s-5.5-9-13.09-9H36.61V48.54Zm5.5,44.72c8,0,13.66-4,13.66-9.57s-5.69-9.57-13.66-9.57H36.61V93.26Z"/>
                        <path d="M210.52,121.62l-27.5-36H171.64v36H134.85V2.09h62.4c29.21,0,49.88,17.22,49.88,41.76,0,17-9.86,30.45-25.79,37.23l31.1,40.54ZM171.64,55h22.57c8.73,0,15-4.7,15-11.13s-6.26-11.14-15-11.14H171.64Z"/>
                        <path d="M257.18,103.53l20.48-22.45c13.28,9.57,27.69,14.62,43.06,14.62,10,0,15.36-3.13,15.36-8.35,0-5.75-4.55-6.62-22.57-10.44C285.25,71,263.44,64.2,263.44,39.32,263.44,15.49,283.92,0,314.84,0c22.57,0,40.21,5.57,54.62,16.18L351.06,40c-12.14-8-25.41-12-37.17-12-8.92,0-13.28,3.31-13.28,7.83,0,6.09,4.55,8,23.14,11.66,30.35,5.92,49.31,13.4,49.31,36.19,0,25.23-21.05,40-53.67,40C295.87,123.71,273.68,116.93,257.18,103.53Z"/>
                        <path d="M421,32.19h-38.5V2.09h113.8v30.1h-38.5v89.43H421Z"/>
                        <path d="M736.82,121.62l-27.5-36H697.94v36H661.15V2.09h62.4c29.21,0,49.88,17.22,49.88,41.76,0,17-9.86,30.45-25.79,37.23l31.1,40.54ZM697.94,55h22.57c8.73,0,15-4.7,15-11.13s-6.26-11.14-15-11.14H697.94Z"/>
                        <path d="M823.31,82.3,773.24,2.09h42.1l26.37,48.54L869.59,2.09H910L860.1,82.3v39.32H823.31Z"/>
                        <path className="icon" d="M517.37,2.09V121.62H636.91V2.09ZM577.14,12.3a9,9,0,1,1-8.95,9A9,9,0,0,1,577.14,12.3Zm22.93,75H554.68V41.89h45.39Z"/>
                </svg>
                </Box>
            </React.Fragment>
        );
    }

}