import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid"
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Box from '@material-ui/core/Box';

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';


import './../LayoutBooking.scss';


export default class LayoutBookingCustomization extends Component {

    renderAddOns() {
        let output = [];

        this.props.addOns.forEach((item, i) => {
                let checked = false;
                let price = item.type === "time" ? "$"+item.price+"/hr" : "$"+item.price;
                let selected = ""
                let subtitle = null

                    // label state
                    if(this.props.booking.package_add_on.filter(function(e) { return e.title === item.value; }).length > 0) {
                        checked = true;
                        selected = "--selected"
                    } else { 
         
                        selected = ""
                    }

                    // Item with subtitle
                    if (item.hasOwnProperty("subtitle")) {
                        subtitle = 
                        <div className="name-subtitle">
                            {item.subtitle}
                        </div>
                    }

                output.push(
                    <React.Fragment key={i}>
                        <Grid item xs={12} md={6} >
                                <FormControlLabel
                                    className={"add-on " + ( selected ? "--selected" : "") + (this.props.isDisabled ? " --disabled" : "") }  
                                    control={
                                        <React.Fragment>
                                            <div className="add-on_name">
                                                <Checkbox
                                                    checked={checked} 
                                                    onChange={this.props.handleAddOnChange}
                                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                    name={item.value}
                                                    id={item.value}
                                                    color="primary"
                                                    value={item.price}
                                                />
                                                <div >
                                                    <div className="name-title">{item.title}</div>
                                                    {subtitle}
                                                </div>
                                            </div>
                                            <div className="add-on_price">{price}</div>
                                        </React.Fragment>
                                    }
                                    label="Additional Hours - Operational Hours"
                                />
                        </Grid>
                    </React.Fragment>
                )
            })

        return output;
    }

    renderBackdropList() {
        let output = [<MenuItem key={"Undecided"} onClick={this.props.handleCloseDropdownMenu.bind(this, "package_backdrop", "Undecided")}>Undecided</MenuItem>]
        
        this.props.backdrops.forEach((item, i) => {
                let title = item.type + " - " + item.name
                output.push(
                    <MenuItem key={i} onClick={this.props.handleCloseDropdownMenu.bind(this, "package_backdrop", title)}>{title}</MenuItem>
                )
        })

            
        return output
    }

    renderEventService() {
        
        let output = []
        
        this.props.eventService.forEach((item, i) => {
                let title = item.type
                output.push(
                    <MenuItem key={i} onClick={this.props.handleCloseDropdownMenu.bind(this, "event_service", title)}>{title.replace("-", " ")}</MenuItem>
                )
        })
            
        return output
    }

    renderEventType() {
        let output = []
        
        this.props.eventTypes.forEach((item, i) => {
                let title = item.type
                output.push(
                    <MenuItem key={i} onClick={this.props.handleCloseDropdownMenu.bind(this, "event_type", title)}>{title}</MenuItem>
                )
        })
            
        return output
    }

    renderTheRest() {
        let output = ""
            
            if(this.props.booking.event_type !== "corporate") {
                output = 
                <>
                <Grid item xs={12} md={12}>
                            <Typography  variant="body2"  component="h5" className="f_header-subtitle">
                                How long do you want to book our photobooth for ? 
                            </Typography>
                            <Grid component="section" container  spacing={3} >
                                <Grid item xs={12} md={12}>
                                        <TextField 
                                                inputProps={{ min: "2", max: "10", step: "1" }}
                                                required 
                                                defaultValue={this.props.booking.package_hour} 
                                                type="number"  
                                                id="package_hour" 
                                                name="package_hour" 
                                                label="Hour"  
                                                variant="outlined" 
                                                disabled={this.props.isDisabled} 
                                                onChange={this.props.handleInputChange} 
                                                fullWidth 
                                            />
                                </Grid>
                            </Grid>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <Typography  variant="body2"  component="h5" className="f_header-subtitle">
                            Included in the package (Backdrop)
                        </Typography>
                        <Grid component="section" container  spacing={3} >
                                <Grid item xs={12} md={6} >
                                    <Button 
                                        className="btn_custom-dropdown"
                                        variant="outlined" 
                                        aria-controls="simple-menu" 
                                        aria-haspopup="true" 
                                        id="package_backdrop" 
                                        onClick={this.props.handleOpenDropdownMenu}
                                        disabled={this.props.isDisabled}
                                        endIcon={<KeyboardArrowDownIcon />}
                                        fullWidth
                                        >
                                        {this.props.booking.package_backdrop === "" ? "Select your backdrop" : this.props.booking.package_backdrop}
                                    </Button>
                                    <Menu
                                        id="package_backdrop"
                                        MenuListProps={{ min: "2", max: "10", step: "1" }}
                                        anchorEl={this.props.package_backdrop}
                                        keepMounted
                                        PaperProps={{
                                            style: {
                                            maxHeight: 48 * 4.5
                                            },
                                        }}
                                        open={Boolean(this.props.package_backdrop)}
                                        onClose={this.props.handleCloseDropdownMenu.bind(this, "package_backdrop", null)}
                                    >
                                        {this.renderBackdropList()}
                                        
                                    </Menu>
                                </Grid>
                                {/* <Grid item xs={12} md={6} >
                                    <Button 
                                        className="btn_custom-dropdown"
                                        variant="outlined" 
                                        aria-controls="simple-menu" 
                                        aria-haspopup="true" 
                                        id="package_backdrop" 
                                        onClick={this.props.handleOpenDropdownMenu}
                                        disabled={this.props.isDisabled}
                                        endIcon={<KeyboardArrowDownIcon />}
                                        fullWidth
                                        >
                                        {this.props.booking.package_backdrop === "" ? "Select your template" : this.props.booking.package_backdrop}
                                    </Button>
                                    <Menu
                                        id="package_backdrop"
                                        MenuListProps={{ min: "2", max: "10", step: "1" }}
                                        anchorEl={this.props.package_backdrop}
                                        keepMounted
                                        PaperProps={{
                                            style: {
                                            maxHeight: 48 * 4.5
                                            },
                                        }}
                                        open={Boolean(this.props.package_backdrop)}
                                        onClose={this.props.handleCloseDropdownMenu.bind(this, "package_backdrop", null)}
                                    >
                                        {this.renderBackdropList()}
                                        
                                    </Menu>
                                    
                                </Grid> */}
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <Typography  variant="body2"  component="h5" className="f_header-subtitle">
                            Add Ons (Addtional Fee)
                        </Typography>
                        <Grid component="section" container  spacing={3} >
                        {this.renderAddOns()}
                        </Grid>
                    </Grid>
                </>
            }
        
        return output
    }

    render() {

        let eventTypeOther = null 

            if(this.props.booking.event_type === "other") {
                eventTypeOther = 
                <Grid item xs={12} md={6}>
                        <TextField 
                            defaultValue="" 
                            id="event_type_custom" 
                            name="event_type_custom" 
                            label="Event Name" 
                            variant="outlined" 
                            disabled={this.props.isDisabled}
                            onChange={this.props.handleInputChange} 
                            fullWidth 
                        />
                </Grid>
            }
        
        let openAir = ""

            if (this.props.booking.event_service === "on-site") {
                openAir = this.renderTheRest()
            }
        return (
            <React.Fragment>
            
                <Grid  container spacing={3} className="f_package-customization">
                        <Grid item xs={12} md={12}>
                            <Box className="f_header">
                                <Typography variant="h4" component="h3">
                                    <span className="f_header_step">{this.props.idx}.</span>The Package
                                </Typography>
                                <Typography >
                                    Build your package the way you see fit. If there are some items that we are missing, feel free to ask us below. 
                                </Typography>
                            </Box>        
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <Typography  variant="body2"  component="h5" className="f_header-subtitle">
                                What type of booth are you looking for ? 
                            </Typography>
                            <Grid component="section" container  spacing={3} >
                                    <Grid item xs={12} md={6} >
                                        <Button 
                                            className="btn_custom-dropdown"
                                            variant="outlined" 
                                            aria-controls="simple-menu" 
                                            aria-haspopup="true" 
                                            id="event_service" 
                                            onClick={this.props.handleOpenDropdownMenu}
                                            disabled={this.props.isDisabled}
                                            endIcon={<KeyboardArrowDownIcon />}
                                            fullWidth
                                            >
                                            {this.props.booking.event_service === "" ? "Select your event" : this.props.booking.event_service.replace("-", " ")}
                                        </Button>
                                        <Menu
                                            id="event_service"
                                            MenuListProps={{ min: "2", max: "10", step: "1" }}
                                            anchorEl={this.props.event_service}
                                            keepMounted
                                            PaperProps={{
                                                style: {
                                                maxHeight: 48 * 4.5
                                                },
                                            }}
                                            open={Boolean(this.props.event_service)}
                                            onClose={this.props.handleCloseDropdownMenu.bind(this, "event_service", null)}
                                        >
                                            {this.renderEventService()}
                                            
                                        </Menu>
                                    </Grid>
                                    {eventTypeOther}
                                    
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <Typography  variant="body2"  component="h5" className="f_header-subtitle">
                                What type of event is this?  
                            </Typography>
                            <Grid component="section" container  spacing={3} >
                                    <Grid item xs={12} md={6} >
                                        <Button 
                                            className="btn_custom-dropdown"
                                            variant="outlined" 
                                            aria-controls="simple-menu" 
                                            aria-haspopup="true" 
                                            id="event_type" 
                                            onClick={this.props.handleOpenDropdownMenu}
                                            disabled={this.props.isDisabled}
                                            endIcon={<KeyboardArrowDownIcon />}
                                            fullWidth
                                            >
                                            {this.props.booking.event_type === "" ? "Select your event" : this.props.booking.event_type}
                                        </Button>
                                        <Menu
                                            id="event_type"
                                            MenuListProps={{ min: "2", max: "10", step: "1" }}
                                            anchorEl={this.props.event_type}
                                            keepMounted
                                            PaperProps={{
                                                style: {
                                                maxHeight: 48 * 4.5
                                                },
                                            }}
                                            open={Boolean(this.props.event_type)}
                                            onClose={this.props.handleCloseDropdownMenu.bind(this, "event_type", null)}
                                        >
                                            {this.renderEventType()}
                                            
                                        </Menu>
                                    </Grid>
                                    {eventTypeOther}
                                    
                            </Grid>
                        </Grid>

                        {openAir}
                        
                        
                
                </Grid>
            </React.Fragment>
        );
    }
}