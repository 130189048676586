import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';


import './../LayoutBooking.scss';

export default class LayoutBookingStart extends Component {
    render() {
        return (
            <>
                <Box component="div" className="page-hero --sub-page"> 
                    {/* <Typography variant="h5" component="h1">
                        Booking
                    </Typography> */}
                    <Typography variant="h1" component="h1" className="--icon">
                        Enhance your event experience today.
                    </Typography>
                </Box>
            </>
        );
    }
}
