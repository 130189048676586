import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';



import './Service.scss';

const features = [
    {
        title: "Green Screen",
        img : "assets/img/vb_features_green-screen_small.gif"
    },
    {
        title: "Filters",
        img : "assets/img/vb_features-filters_small.gif"
    },
     {
        title: "Stickers",
        img : "assets/img/vb_features_stickers_large.gif"
    }, 
    {
        title: "Social Sharing",
        img : "assets/img/vb_features_social-sharing.jpg"
    },
    {
        title: "Gallery",
        img : "assets/img/vb_features_gallery.gif"
    },
    {
        title: "Compatible on Desktop, tablet and smartphone",
        img : "assets/img/vb_multiple-device.png"
    }
]
export default class ServiceOnSite extends Component {

    constructor(props) {
        super(props);

        this.state = {
            features: features
        }
    }

    componentDidMount = () => {
        document.getElementsByTagName("BODY")[0].className="p-on-site"
    }
    
    componentWillUnmount = () =>{
        // remove class
        document.getElementsByTagName("BODY")[0].className=""
    }

    renderFeatures  = () => {
        let output = []
        // let imgURL = "assets/img/backdrop"
        
        this.state.features.forEach(function (feature, i) {
            output.push(
                <Grid item xs={12} md={6} lg={4} key={i}>
                    <Card elevation={0} className="item"> 
                             <Box className="img-wrapper">
                                <CardMedia
                                    component="img"
                                    alt={feature.name}
                                    height="140"
                                    image={`${feature.img}`}
                                    title={feature.title}
                                />
                                </Box>
                            <CardContent>
                            <Typography align="center" gutterBottom variant="h6" component="h3">
                                {feature.title}
                            </Typography>
                            {/* <Typography align="center" variant="body1" color="textSecondary" component="p">
                                {feature.type}
                            </Typography> */}
                            </CardContent>
                    </Card>
                </Grid>
            )
        })

        return output
    }

    handleGotoBooking = () => {
        this.props.history.push({
            pathname:`/booking`
        })
    }

    render() {
        return  (
            <>
        
            <Box component="div" className="page-hero --sub-page --center"> 
                {/* <CommonBGLines/> */}
                <Container component="div" maxWidth="lg" fixed >
                    {/* <Typography variant="h5" component="h1">
                        Backdrops
                    </Typography> */}
                    <Typography variant="h1" component="h1" className="--icon">
                        On Site Booth
                    </Typography> 
                    <Container component="div" maxWidth="md" fixed >
                        <Typography className="lead" align="center" variant="h5" color="textSecondary" component="p" gutterBottom>
                            Our on site booth will be the talk of the night - a way for your friends to be silly and have a souvenir
                        </Typography>
                        {/* <Typography align="center" color="textSecondary" component="p" className="text-color-primary">
                            <strong>No App Needed + Branded Interface</strong>
                        </Typography>      */}
                    </Container>            
                </Container>
            </Box>
            <Box component="main">
                {/* <Container component="section" maxWidth="lg" fixed>  

                    <Box className="vb-device">
                            <img className="img" src="assets/img/vb_hero-center.png" alt="Hero center image"/>
                    </Box>
                    <Box className="vb-hero-image">
                        <Box className="vb-hero-image_example --left">
                            <img className="img" src="assets/img/vb_hero-left.png" alt="Hero center image"/>
                     
                        </Box>
                        <Box className="vb-hero-image_example --right">
                            <img className="img" src="assets/img/vb_hero-right.png" alt="Hero center image"/>
                        </Box>
                    </Box>
                </Container> */}
                <Container component="section" maxWidth="md" fixed>  
                    <Typography align="center" variant="h4"  gutterBottom>
                        <strong>On-site booth is perfect all kinds of events. Perfect for: weddings, online conferences, birthdays & more</strong>
                    </Typography>
                </Container>
                <Box  component="section" className="os_image-placeholder " >
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} className="content-img">
                            <img className="img" src="assets/albums/5/ch5D-3.jpg" alt="BRSTORY"/>
                        </Grid>
                    </Grid>
                </Box>

                     
                <Box id="personalize" component="section" className="os_features" >
                    <Box className="header_sub-title">
                        <Typography variant="h3" component="h2" >
                                Personalize your event
                        </Typography>
                        <Typography variant="h6" component="h5">
                            Doesn't matter what kind of event it is, we will have the perfect backdrops and print design for it.
                        </Typography>
                    </Box>
                    <Box className="overlay-pattern">
                        <Container fixed>
                                <Grid container spacing={4} component="section">
                                        <Grid item  xs={12} md={7} className="personalization_img personalize_print-design">
                                            <Box component="figure" className="product-image product-image_template">
                                                <img src={`${process.env.REACT_APP_IMAGE_URL}/img/hero_template.png?v=1`} alt="film-strip"/>
                                                {/* <div className="product-image_underlay"></div> */}
                                            </Box>
                                    
                                        </Grid>

                                        <Grid item xs={12} md={5} className="personalize_copy">
                                            <Box>
                                                <Typography variant="h5" component="h3">
                                                    Template Design
                                                </Typography>
                                                <Typography variant="body1" component="p">
                                                    We have many beautiful pre-designed templates for you to choose from that cater to different events. 
                                                    If you would like something more personalized, you can upgrade to our customized template. 
                                                    This will allow you to provide us with your preferred layout, text and colours.  
                                                </Typography>
                                                {/* <Button   size="small" className="book-now btn-underline" onClick={e =>  this.props.history.push({pathname:`/templates`})}>
                                                    <span className="arrow"><ArrowForwardIosOutlinedIcon /> </span><span className="text">More Templates</span>
                                                </Button> */}
                                            </Box>
                                        </Grid>
                                </Grid>
                                <Grid container spacing={4} component="section">
                                    
                                        <Grid item  xs={12} md={7} className="personalization_img personalize_backdrop">
                                            <Box component="figure" className="product-image product-image_template">
                                                <img src={`${process.env.REACT_APP_IMAGE_URL}/img/hero_backdrop.png`} alt="backdrop"/>
                                                {/* <div className="product-image_underlay"></div> */}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={5} className="personalize_copy">
                                            <Box>
                                                <Typography variant="h5" component="h3">
                                                    Backdrops
                                                </Typography>
                                                <Typography variant="body1" component="p">
                                                    Choose from a variety of backdrops to enhance your photos! From Gold Sequins to high quiality solid color fabrics. 
                                                </Typography>
                                                <Button  size="small" className="book-now btn-underline" onClick={e =>  this.props.history.push({pathname:`/backdrops`})}>
                                                    <span className="arrow"><ArrowForwardIosOutlinedIcon /> </span><span className="text">More Backdrops</span>
                                                </Button>
                                            </Box>
                                        </Grid>
                                </Grid>
                            
                        </Container>
                    </Box>
                </Box>

                <Box component="section"  className="os_cta" >
                    <Container  maxWidth="md" fixed>  
                        <Typography align="center" variant="h4"  gutterBottom>
                            <strong>Enhance your next event by booking BRSTORY photobooth.</strong>
                        </Typography>
                        <Button  className="btn_book-now --desktop " variant="contained" size="large"  color="primary"  onClick={this.handleGotoBooking.bind()}>
                            Book Today
                        </Button>
                    </Container>
                </Box>
                
               
            </Box>
            </>
        )
    }
}