import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import CommonShape from '../Common/DividerShape'
import CommonFollowUs from '../Common/FollowUs'

import './Backdrops.scss';

export default class Backdrops extends Component {

    constructor(props) {
        super(props);

        this.state = {
            backdrops: this.props.backdrops
        }
    }

    componentDidMount = () => {
        document.getElementsByTagName("BODY")[0].className="p-backdrops"

        if (this.props.backdrops.length === 0){
            this.props.get("backdrops", "").then(responses => {
                this.props.handleGlobalStateChange({backdrops: responses})
            })
        }
    }
    
    componentWillUnmount = () =>{
        // remove class
        document.getElementsByTagName("BODY")[0].className=""
    }

    renderBackdrops  = () => {
        let output = []
        let imgURL = "assets/img/backdrop"
        // let imgURL = "https://storage.cloud.google.com/www.brstory.ca/assets/img/backdrop"
        
        this.props.backdrops.forEach(function (backdrop, i) {
            output.push(
                <Grid item xs={6} md={6} lg={4} key={i}>
                    <Card elevation={0} className="item"> 
                            
                            <CardMedia
                                component="img"
                                alt={backdrop.name}
                                height="140"
                                image={`${imgURL}/${backdrop.code}.png`}
                                title={backdrop.name}
                            />
                            <CardContent>
                            <Typography align="center" gutterBottom variant="h6" component="h3">
                                {backdrop.name}
                            </Typography>
                            <Typography align="center" variant="body1" color="textSecondary" component="p">
                                {backdrop.type}
                            </Typography>
                            </CardContent>
                    </Card>
                </Grid>
            )
        })

        return output
    }

    render() {
        return  (
            <>
        
            <Box component="div" className="page-hero --sub-page --center"> 
                {/* <CommonBGLines/> */}
                <Container component="div" maxWidth="lg" fixed >
                    {/* <Typography variant="h5" component="h1">
                        Backdrops
                    </Typography> */}
                    <Typography variant="h1" component="h1" className="--icon">
                        Backdrops
                    </Typography> 
                </Container>
            </Box>
            <Box component="main" className="--overlap-effect">
                <CommonShape/>
                <Container component="section" maxWidth="lg" fixed>  
                    <Grid  container spacing={3} className="product-gallery backdrops">
                        {this.renderBackdrops()}
                    </Grid>
                </Container>
                <CommonFollowUs/>
            </Box>
            </>
        )
    }
}