import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import CommonFollowUs from '../Common/FollowUs'

import Carousel from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Home.scss';

import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';

// import DateUtils from '@date-io/dayjs';

// import {
//     MuiPickersUtilsProvider,
//     TimePicker,
//     DatePicker,
//   } from '@material-ui/pickers';

export default class Home extends Component {

    constructor(props) {
        super(props);

        this.state = {
            albums: [],
            slickSlider : {
                album : {
                    className: "center",
                    centerMode: true,
                    infinite: true,
                    centerPadding: "9%",
                    slidesToShow: 2,
                    speed: 500,
                    responsive: [
                         {
                          breakpoint: 1280,
                          settings: {
                            centerMode: true,
                            centerPadding: "12%"
                          }
                        },
                        {
                          breakpoint: 960,
                          settings: {
                            centerMode: true,
                            centerPadding: "13%",
                            slidesToShow: 1
                          }
                        },
                        {
                          breakpoint: 600,
                          settings: {
                            arrows: false,
                            centerMode: true,
                            centerPadding: '4%',
                            slidesToShow: 1
                          }
                        }
                      ]
                },
                behindTheScene : {
                    className: "center",
                    centerMode: true,
                    infinite: true,
                    centerPadding: "200px",
                    slidesToShow: 1,
                    speed: 500,
                    responsive: [
                        {
                         breakpoint: 1280,
                         settings: {
                           centerMode: true,
                           centerPadding: "12%"
                         }
                       },
                       {
                         breakpoint: 960,
                         settings: {
                           centerMode: true,
                           centerPadding: "13%",
                           slidesToShow: 1
                         }
                       },
                       {
                         breakpoint: 600,
                         settings: {
                           arrows: false,
                           centerMode: true,
                           centerPadding: '4%',
                           slidesToShow: 1
                         }
                       }
                     ]
                }
            }
        }
    }

    componentDidMount = () => {
        // append class
        document.getElementsByTagName("BODY")[0].className="p-home"
        if (this.props.albums.length === 0){
            this.props.get("bookings",{ status : "complete"}).then(responses => {
                this.props.handleGlobalStateChange({albums: responses})
            })
        }
    }
    
    componentWillUnmount = () =>{
        // remove class
        document.getElementsByTagName("BODY")[0].className=""
    }

    handlePriceChange = (event, value) => {
        if (this.props.booking.package_hour !== value) {
            this.props.handleUpdateBooking("package_hour", value)
        }
    }

    handleClientRedirect = (id, album) => {

        this.props.history.push({
            pathname:`/client/${album.album._ch}`, 
            state: {
                _id:`${id}`,
                state: album
            }
        })
    }

    handleGotoBooking = (service) => {
        this.props.history.push({
            pathname:`/booking`
        },this.props.handleUpdateBooking("event_service", service))
        
    }

    handleGotoPage = (page) => {
        this.props.history.push({
            pathname:`/${page}`
        })
    }

    handleDateTimeChange = (date) => {
        this.props.handleUpdateBooking("event_date", date) 
    }

    handleScrollTo = () => {
         var anchor = document.getElementById("about-us");

            window.scrollTo({
                'behavior': 'smooth',
                'left': 0,
                'top': (anchor.offsetTop + 40)
            });
    }

    handleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;     
        this.props.handleUpdateBooking(name, value)
    }


    renderAlbumsSlider  = () => {
        let output = []
        
        this.props.albums.forEach(function (album, i) {
            let id = album._id
            let chapter = album.album._ch
            let eventName = album.album.name

            if (i < 3) {
                output.push(
                    <div className="media client --show" key={i}>
                        <Box className="client_image" style={{backgroundImage: `url(${process.env.REACT_APP_IMAGE_URL}/albums/${chapter}/square-image.jpg)`}} onClick={this.handleClientRedirect.bind(this, id, album)}>
                            <Box className="client_body">
                                <Typography variant="subtitle1" component="h6">
                                    Chapter {chapter}
                                </Typography>
                                <Typography className="client_body-title" variant="h5" component="h4">
                                    {eventName}
                                </Typography>
                            </Box>
                        </Box>
                    </div>
                )
            }
         
        }.bind(this))

        return output
    }

    render() {

    let    tweenTo = {
                    transform:"scale(0.8, 0.8)",
                    // marginTop:"100px"
                    // backgroundColor: "red",
                }

        return (
            <Box as="main">
                {/* Section: Hero */}
                <Controller>
                <Scene duration={300} classToggle="--scrolled" triggerHook={0} triggerElement="#" indicators={false}>
                        {(progress, event) => (
                            <Tween            
                                to={tweenTo}  
                        
                                ease="Expo.easeOut"
                                totalProgress={progress}
                                paused

                            >
                                <Box  id="client-spotlight" component="section" className="tween hero page-hero --background-layered"> 
                                    {/* <div className="scroll-down" onClick={this.handleScrollTo}>Scroll</div> */}
                                    
                                    {/* <Container  component="div" className="hero-content " fixed>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={6} className="content-copy">
                                   
                                                <Typography variant="h1" component="h1">
                                                    No Apps.<br/>No Downloads.<br/>Any Device.
                                                </Typography> 
                                                <Typography className="lead" variant="h5" component="p">
                                                    We are happy to announce our browser based virtual photo booth. 
                                                </Typography>
                                                <Button  variant="outlined"  onClick={this.handleGotoPage.bind(this, "virtual-booth")}>
                                                    Learn More
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md={6} className="content-img">
                                                <img className="img" src="assets/img/home_hero-image_vb.png" alt="Virtual Booth"/>
                                            </Grid>
                                             
                                        </Grid>
                                    </Container> */}
                                    
                                    <Container  component="div" className="hero-content --center" fixed>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={12} >
                                              
                                                <Typography variant="h1" component="h1">
                                                    We will be back...
                                                </Typography> 
                                                <Typography className="lead" variant="h5" component="p">
                                                    We are taking a small break and will not be taking any booking for 2021.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Container>

                                    <Box component="div" className="hero_overlay "></Box>
                                    {/* <Box component="section" id="quick-booking-wrapper">
                                        <Container  fixed>
                                            <Box className="quick-booking">
                                                <Grid  container  spacing={3} >
                                                        <MuiPickersUtilsProvider utils={DateUtils}>
                                                            <Grid item xs={12} md={3}>
                                                                <DatePicker
                                                                    required
                                                                    format="MMMM DD YYYY"
                                                                    id="event_day"
                                                                    name="event_day"
                                                                    label="Event Date"
                                                                    value={this.props.booking.event_date}
                                                                    onChange={this.handleDateTimeChange}
                                                                    inputVariant="standard"
                                                                    animateYearScrolling
                                                                    disabled={this.props.isDisabled}
                                                                    InputProps={{ disableUnderline: true }}
                                                                    fullWidth
                                                                />
                                                        
                                                            </Grid>
                                                            <Grid item xs={12} md={2}>
                                                                <TimePicker
                                                                    required
                                                                    id="time-picker"
                                                                    label="Start Time"
                                                                    placeholder="08:00 AM"
                                                                    mask="__:__ _M"
                                                                    value={this.props.booking.event_date}
                                                                    onChange={this.handleDateTimeChange}
                                                                    disabled={this.props.isDisabled}
                                                                    InputProps={{ disableUnderline: true }}
                                                                    inputVariant="standard"
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                        </MuiPickersUtilsProvider>
                                                        <Grid item xs={12} md={2}>
                                                            <TextField 
                                                                inputProps={{ min: "2", max: "10", step: "1" }}
                                                                required 
                                                                defaultValue={this.props.booking.package_hour} 
                                                                type="number"  
                                                                id="package_hour" 
                                                                name="package_hour" 
                                                                label="Total hours?"  
                                                                variant="standard"  
                                                                disabled={this.props.isDisabled}
                                                                onChange={this.handleInputChange} 
                                                                InputProps={{ disableUnderline: true }}
                                                                fullWidth 
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={2}>
                                                                <TextField 
                                                                    required 
                                                                    defaultValue={this.props.booking.event_guest_number} 
                                                                    type="number"  
                                                                    id="event_guest_number" 
                                                                    name="event_guest_number" 
                                                                    label="Guests Numbers"  
                                                                    variant="standard"  
                                                                    disabled={this.props.isDisabled}
                                                                    onChange={this.handleInputChange} 
                                                                    InputProps={{ disableUnderline: true }}
                                                                    fullWidth 
                                                                />
                                                        </Grid>
                                                        <Grid item xs={12} md={3}>
                                                                <Button className="btn_book-now --accent" fullWidth={true} variant="contained" size="large"  onClick={this.handleGotoBooking.bind()}>
                                                                    Book Now
                                                                </Button>
                                                        </Grid>      
                                                </Grid>
                                            </Box>
                                        </Container>
                                    </Box> */}
                                </Box>
                            </Tween>    
                        )}
                </Scene>
                </Controller>
                
                <Box id="about-us" component="section" className="slider">
                    <Container fixed className="our-story">
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6} className="about-us_copy">
                                <Box className="header_sub-title">
                                    <Typography variant="h3" component="h2" >
                                        Be part of our photobooth journey
                                </Typography>
                                </Box>
                                <Typography>
                                Whether it’s a <strong>wedding</strong>, <strong>birthday</strong>, <strong>corporate events</strong> or anything in between, BRSTORY is uniquely equipped to provide you with a 100% customizable photo booth.
                                We believe in quality product and service; we settle on nothing less than excellence.                       
                                </Typography>
                                <br/>
                                <Typography>
                                Our expert team is there to install your photo booth, facilitate the fun, and take it down at the end of the event—delivering a totally seamless experience. 
                                Your guests will walk away with photo keepsakes to remind them of their night.                    
                                </Typography>
                                <Box id="collaboration" component="section" >
                                    <Container fixed>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={12} >
                                            
                                                <Typography variant="body1" component="h5" className="collaboration_title">
                                                    <strong>Brand's we worked with: </strong>
                                                </Typography>
                                                <ul>
                                                    <li><img src={`${process.env.REACT_APP_IMAGE_URL}/img/brand-amazon.png`} alt="text"/></li>
                                                </ul>
                                            
                                            </Grid>
                                        </Grid>
                                        {/* <Grid container spacing={3}>
                                            <Grid item xs={12} md={6}>
                                                Something goes here
                                            </Grid>
                                        </Grid> */}
                                    </Container>
                                </Box>
                            </Grid>
                            <Grid item  xs={12} md={6} >
                                <Box component="figure" >
                                    <img src={`${process.env.REACT_APP_IMAGE_URL}/img/template_sabrinas-first-birthday.png?v=1`} alt="Birthday Photobooth"/>
                                    {/* <div className="product-image_underlay"></div> */}
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                    {/* <Box id="quick-booking-wrapper" component="section" className="--no-shadow" >
                                        <Container  fixed>
                                            <Box className="quick-booking">
                                                <Grid  container  spacing={3} >
                                                        <MuiPickersUtilsProvider utils={DateUtils}>
                                                            <Grid item xs={12} md={3}>
                                                                <DatePicker
                                                                    required
                                                                    format="MMMM DD YYYY"
                                                                    id="event_day"
                                                                    name="event_day"
                                                                    label="Event Date"
                                                                    value={this.props.booking.event_date}
                                                                    onChange={this.handleDateTimeChange}
                                                                    inputVariant="standard"
                                                                    animateYearScrolling
                                                                    disabled={this.props.isDisabled}
                                                                    InputProps={{ disableUnderline: true }}
                                                                    fullWidth
                                                                />
                                                        
                                                            </Grid>
                                                            <Grid item xs={12} md={2}>
                                                                <TimePicker
                                                                    required
                                                                    id="time-picker"
                                                                    label="Start Time"
                                                                    placeholder="08:00 AM"
                                                                    mask="__:__ _M"
                                                                    value={this.props.booking.event_date}
                                                                    onChange={this.handleDateTimeChange}
                                                                    disabled={this.props.isDisabled}
                                                                    InputProps={{ disableUnderline: true }}
                                                                    inputVariant="standard"
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                        </MuiPickersUtilsProvider>
                                                        <Grid item xs={12} md={2}>
                                                            <TextField 
                                                                inputProps={{ min: "2", max: "10", step: "1" }}
                                                                required 
                                                                defaultValue={this.props.booking.package_hour} 
                                                                type="number"  
                                                                id="package_hour" 
                                                                name="package_hour" 
                                                                label="Total hours?"  
                                                                variant="standard"  
                                                                disabled={this.props.isDisabled}
                                                                onChange={this.handleInputChange} 
                                                                InputProps={{ disableUnderline: true }}
                                                                fullWidth 
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={2}>
                                                                <TextField 
                                                                    required 
                                                                    defaultValue={this.props.booking.event_guest_number} 
                                                                    type="number"  
                                                                    id="event_guest_number" 
                                                                    name="event_guest_number" 
                                                                    label="Guests Numbers"  
                                                                    variant="standard"  
                                                                    disabled={this.props.isDisabled}
                                                                    onChange={this.handleInputChange} 
                                                                    InputProps={{ disableUnderline: true }}
                                                                    fullWidth 
                                                                />
                                                        </Grid>
                                                        <Grid item xs={12} md={3}>
                                                                <Button className="btn_book-now --accent" fullWidth={true} variant="contained" size="large"  onClick={this.handleGotoBooking.bind()}>
                                                                    Book Now
                                                                </Button>
                                                        </Grid>      
                                                </Grid>
                                            </Box>
                                        </Container>
                                    </Box> */}
                </Box>

               

                <Box id="recent-album" component="section">
                    <Container fixed > 
                        <Grid  container  spacing={3} >
                            <Grid item xs={12} md={6}>
                                <Box className="header_sub-title">
                                    <Typography variant="h3" component="h2">
                                        Our recent stories
                                    </Typography>
                                    <Typography variant="h6" component="h5">
                                        Check out our past photoboothers
                                    </Typography>

                                </Box>
                            </Grid>                                     
                        </Grid>

                        {/* <Grid  container  spacing={3} >
                            <Grid item xs={12} >
                                
                                <Box className="album-wrapper">
                                    {this.renderAlbums()}      
                                </Box>
                            </Grid>                                     
                        </Grid>                        */}
                    </Container>
                    <Box className="client-albums">
                            <Carousel className="--carousel" {...this.state.slickSlider.album}>
                                {this.renderAlbumsSlider()}                    
                            </Carousel>
                     
                        </Box>         
                    <Container fixed className="client-albumbs_link" display="block"  align="left">
                            
                            <Button   size="small" className="book-now btn-underline" onClick={e =>  this.props.history.push({pathname:`/albums`})}>
                                    <span className="arrow"><ArrowForwardIosOutlinedIcon /> </span><span className="text">More Stories</span>
                            </Button>
                    </Container>
                </Box>
               
                {/* Section: Features */}
                {/* <Box id="type-of-service" component="section" className="features" >
                    <Container fixed>
                            <Box className="header_sub-title">
                                <Typography variant="h3" component="h2" >
                                        Features that's included
                                </Typography>
                  
                            </Box>
                            <Grid container spacing={3}>
                                <Grid item xs={6} md={3}>
                                    <Card elevation={0} className="service_item">
                                        <CardMedia
                                            component="img"
                                            alt="Unlimited Printing"
                                            image={"assets/img/feature_professional-equipment.jpg"}
                                            title="Unlimited Printing"
                                        />
                                        <CardContent>
                                        <Typography  variant="h5" component="h4">
                                            Unlimited<br/>Printing
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                        We make sure every guest in the photo receives a lab quality print of every photo they are in! An amazing keepsake for your guests!
                                        </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <Card elevation={0} className="service_item">
                                            <CardMedia
                                                component="img"
                                                alt="Online access"
                                                image={"assets/img/feature_professional-equipment.jpg"}
                                                title="Online access"
                                            />
                                            <CardContent>
                                            <Typography  variant="h5" component="h4">
                                                Online<br/>Access
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                Private and secure digital gallery after the event for you to share with your guests. We make sure every guest receives a lab quality print of every photo they are in!                                         
                                            </Typography>
                                            </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <Card elevation={0} className="service_item">
                                            <CardMedia
                                                component="img"
                                                alt="Abundance of Props"
                                                image={"assets/img/feature_professional-equipment.jpg"}
                                                title="Abundance of Props"
                                            />
                                            <CardContent>
                                            <Typography  variant="h5" component="h4">
                                                Abundance <br/> of Props
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                Comical masks, plastic swords, silly glasses, stylish headbands and many more.                                 
                                            </Typography>
                                            </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <Card elevation={0} className="service_item">
                                            <CardMedia
                                                component="img"
                                                alt="Professional Equipment"
                                                image={"assets/img/feature_professional-equipment.jpg"}
                                                title="Professional Equipment"
                                            />
                                            <CardContent>
                                            <Typography  variant="h5" component="h4">
                                                Professional<br/>Equipment                                           
                                                </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                Professional grade lighting equipment for high quality, beautiful photos.                                 
                                            </Typography>
                                            </CardContent>
                                    </Card>
                                </Grid>
                                
                            </Grid>
                        </Container>
                </Box> */}

                <Box id="booths" component="section" className="type-of-booths" >
                    
                    <Container maxWidth="lg" fixed>
                        <Box className="package-wrapper package-wrapper_build-your-own --on-site">
                            <Grid container spacing={0} > 
                                <Grid item xs={12} md={6} className="package_image">
                                        <img className="img" src="assets/img/home-os.png" alt="On Site Booth"/>
                                </Grid>
                                <Grid item xs={12} md={6} className="list">
                                    <Box className="header_sub-title">
                                        <Typography variant="h3" component="h2" >
                                                On-Site Booth
                                        </Typography>
                                        <Typography variant="h6" component="h5">
                                           Our pricing is very simple, you pay by the hour starting at <strong>$275</strong>. 
                                        </Typography>
                                        
                            
                                    </Box>
                                    <Box className="detail">
                                        <Typography component="p">
                                            <strong>Package includes</strong>
                                        </Typography>
                                        <Grid container spacing={0} > 
                                            <Grid item xs={12} md={6} >
                                                <ul className="package-services ">
                                                    <li >Booth rental time</li>
                                                    <li>Unlimited prints (2x6 strip)<sup>**</sup></li>
                                                    <li>Pre-designed templates</li>
                                                    <li>Choice of backdrop</li>
                                                    <li>On-site printing</li>
                                                </ul>
                                            </Grid>
                                            <Grid item xs={12} md={6} >
                                                <ul className="package-services ">
                                                    <li>Party props</li>
                                                    <li>2 on site attendants</li>
                                                    <li>Password protected online photo gallery</li>
                                                    <li>Set up &amp; removal</li>     
                                                </ul>
                                            </Grid>
                                            
                                        </Grid>
                                    </Box>
                                    <Box className="footnote">
                                        <Button  className="btn_book-now --desktop MuiButton-disableElevation" variant="contained" size="large"  color="primary"  onClick={this.handleGotoBooking.bind(this, "on-site")}>
                                            Book Now
                                        </Button>
                                        <Typography variant="caption" component="h6">
                                            *Prices and packages subject to change without notice    <br/>
                                            **Additional cost applies                             
                                        </Typography>
                                    </Box>
                                </Grid>
                                   
                               
                            </Grid>
                          
                        </Box>
                    </Container>
                    <div className="package-shadow"></div>
                </Box>

              
               
                <CommonFollowUs/>
            </Box>
                
        );
    }

}