import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from "@material-ui/core/Container"
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import CommonFollowUS from '../Common/FollowUs'


import  dayjs from 'dayjs'

import './Client.scss';

export default class Client extends Component {

    constructor(props) {
        super(props);

        this.state = {
            event_name: "",
            event_date: "",
            event_venue : {
                address:"",
                name: ""
            },
            album: {
                _ch: this.props.match.params.ch,
                name: "",
                images: 15,
                download: false
            },
            images: []
        }
    }
    componentDidMount = () => {
        // append class
        document.getElementsByTagName("BODY")[0].className="p-client"
    
        console.log(this.props)
        if(this.props.location.state !== undefined) {
            this.setState({
                ...this.props.location.state.state
            })
        } else {
            let id = this.props.match.params["ch"];
            console.log(id)
            
            this.props.get("bookings",{ status : "complete"}).then(responses => {
               console.log(responses)
            })

            this.props.get(`_ch${id}`, "").then(responses => {
                console.log(responses)
                // this.setState({
                //     ...responses
                // })
            })
            
        }
    }
    
    componentWillUnmount= () =>{
        // remove class
        document.getElementsByTagName("BODY")[0].className=""
    }

    renderImages  = () => {
        let maxImages = this.state.album.images;
        let imageName = `ch${this.state.album._ch}D`
        let output = []

        for (let i = 0 + 1 ; i < maxImages; i++) {
            let col = 1

                if(!this.props.mobile) {
                    if (i === 0  || i === 2) {
                        col = 2
                    } else if (i === 3) {
                        col = 3
                    }
                }
                
             
                output.push(
                    <GridListTile cols={col} key={i}>
                        <img src={`/${process.env.REACT_APP_IMAGE_URL}/albums/${this.state.album._ch}/${imageName}-${i}.jpg`}  alt={this.state.album.name + "-" + i }/>
                    </GridListTile>
                )
        }

  
        return output
    }

    handleGoBack = () => {
        this.props.history.goBack();
    }

    render() {
        let featureClass = ""
        let featureVideo = ""
        let featureImage = ""
        let featureEventDate = dayjs(this.state.event_date).format("MMMM DD YYYY")
        let imageCol = 3
        let imageColHeight = 300

            if(this.props.mobile) {
                imageCol = 1
                imageColHeight = 100
            }

        if(this.state.album.feature_video || this.state.album.feature_video === "true") {
            featureClass = "--video"
            featureVideo = 
            <Box className="hero_content-video">
                <video autoPlay muted loop id="myVideo">
                        <source src={`${process.env.REACT_APP_IMAGE_URL}/albums/${this.state.album._ch}/hero.mp4`} type="video/mp4"></source>
                </video>
            </Box>
        } else {
            featureClass = "--image"
            featureImage =  `/${process.env.REACT_APP_IMAGE_URL}/albums/${this.state.album._ch}/feature-image.jpg`
        }

        return  (
            <>
            <Box  component="section" className={`hero --center ${featureClass}`}  style={{ backgroundImage:`url(${featureImage})` }}>                    
                <Box className="hero_content-wrapper">
                    <Box className="hero_content">
                        <Typography variant="h2" component="h1">
                           <strong>{this.state.album.name}</strong>
                        </Typography>
                        <Typography variant="subtitle1" component="h6" className="hero_content-date">
                            {featureEventDate}
                        </Typography>
                       
                    </Box>
                    <Box component="figure" className="background_foreground-image"></Box>
                    {/* <Box component="div" className="hero_overlay"></Box> */}
                </Box>

                {featureVideo}
                
            </Box>
            <Container component="main" maxWidth="md" fixed>
                <Box className="client-description "> 
                        
                    <Box fontSize={16} >
                        <Typography variant="h6">
                            {this.state.event_venue.name}
                        </Typography>
                        <Typography variant="caption" >
                            {this.state.event_venue.address}
                        </Typography>
                    </Box>
                     
                    
                    {/* <Box className="actions">
                        <Button type="submit" variant="contained" size="small"  color="primary">
                                Download
                        </Button>
                    </Box> */}
                </Box>
                <Box className="client-images">
                    <GridList   cellHeight={imageColHeight}  cols={imageCol}>    
                        {this.renderImages()}
                    </GridList>
                </Box>
            </Container>
            <CommonFollowUS/>
            </>
        )
    }
}