import React, { Component } from 'react';
import './Common.scss';

export default class CommongDividerShape extends Component {
    render() {
        return (
            <>
                {/* <svg className="crop-circle --placement-bottom"  data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1366 271.21">
                    <path className="cls-1" fill="#ffffff" d="M684,203.21C431.58,203.21,196.62,128.5,0,0V271.21H1366V1.3C1169.78,129,935.56,203.21,684,203.21Z"/>
                </svg> */}
                {/* <svg className="wave-placement-bottom wave-flip" width="1440px" height="278px" viewBox="0 0 1440 278" version="1.1" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"><g id="Sandbox" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><path d="M0,260.670469 C268,291.980818 533.333333,258.847538 796,161.270627 C1058.66667,63.6937169 1273.33333,9.93684108 1440,0 L1440,278 L0,278 L0,260.670469 Z" id="Path-8" fill="#ffffff" transform="translate(720.000000, 139.000000) scale(-1, 1) translate(-720.000000, -139.000000) "></path></g></svg> */}
            </>
        );
    }

}