import React, { Component } from 'react';
import Box from '@material-ui/core/Box';

import './Common.scss';

export default class CommonBGLines extends Component {
    render() {
        return (
            <Box className="bg-lines dark">
                <Box></Box>
                <Box></Box>
                <Box></Box>
                <Box></Box>
                <Box></Box>
                <Box></Box>
                <Box></Box>
                <Box></Box>
            </Box>
        );
    }

}