import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';


import CommonShape from '../Common/DividerShape'
import CommonFollowUs from '../Common/FollowUs'


import  dayjs from 'dayjs'

import './Albums.scss';

export default class Albums extends Component {

    constructor(props) {
        super(props);

        this.state = {
            albums: []
        }
    }

    componentDidMount = () => {
        // append class
        document.getElementsByTagName("BODY")[0].className="p-albums"
        if (this.props.albums.length === 0){
            this.props.get("bookings",{ status : "complete"}).then(responses => {
                this.props.handleGlobalStateChange({albums: responses})
            })
        }
    }
    
    componentWillUnmount = () =>{
        // remove class
        document.getElementsByTagName("BODY")[0].className=""
    }


    handleClick = (album) => {

        this.props.history.push({
            pathname:`/client/${album.album._ch}`, 
            state: {
                _id:`${album._id}`,
                state: album
            }
        })

        
    }

    renderAlbums  = () => {
        let output = []
        
        this.props.albums.forEach(function (album, i) {
            let chapter = album.album._ch
            let eventName  = album.album.name
            let eventDate  = dayjs(album.event_date).format("MMMM DD YYYY") 
            output.push(
                <Grid item xs={12} md={6} lg={4} key={i}>
                    <Card elevation={0} className="item --button" onClick={this.handleClick.bind(this, album)}> 
                        <CardActionArea disableRipple={false} >
                            <CardMedia
                            image={`${process.env.REACT_APP_IMAGE_URL}/albums/${chapter}/feature-image.jpg`}
                            title="Contemplative Reptile"
                            />
                            <CardContent>
                            <Typography align="center" gutterBottom variant="h6" component="h3">
                                {eventName}
                            </Typography>
                            <Typography align="center" variant="body1" color="textSecondary" component="p">
                                {eventDate}
                            </Typography>
                            </CardContent>
                        </CardActionArea>
            
                    </Card>
                </Grid>
            )
        }.bind(this))

        return output
    }

    render() {
        return  (
            <>
            <Box component="div" className="page-hero --sub-page --center"> 
                {/* <CommonBGLines/> */}
                <Container maxWidth="lg" fixed >
                    {/* <Typography variant="h5" component="h1">
                        Albums
                    </Typography> */}
                    <Typography variant="h1" component="h1" className="--icon">
                        Albums
                    </Typography> 
                </Container>
            </Box>
            <Box component="main" className="--overlap-effect">
                <CommonShape/>
                <Container maxWidth="lg" fixed>
                    <Grid  container spacing={3} className="product-gallery albums">
                        {this.renderAlbums()}
                    </Grid>
                </Container>
            </Box>
            <CommonFollowUs/>
            </>
        )
    }
}