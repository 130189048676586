import React, { Component } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { ThemeProvider, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { Controller} from 'react-scrollmagic';

// Utils
import ScrollToTop from './Components/Utility/ScrollToTop'
import  dayjs from 'dayjs'


//Component
import Header from './Components/Common/Header'
import Footer from './Components/Common/Footer'

// Pages
import Home from './Components/Routes/Home';
import Contact from './Components/Routes/Contact';
import Albums from './Components/Routes/Albums';
import Booking from './Components/Routes/Booking';
import Client from './Components/Routes/Client';
import Templates from './Components/Routes/Templates';
import Backdrops from './Components/Routes/Backdrops';
import Pricing from './Components/Routes/Pricing';
import ServiceVirtualBooth from './Components/Routes/ServiceVirtualBooth';
import ServiceOnSite from './Components/Routes/ServiceOnSite';

import './App.scss';
import './assets/styles/main.scss';

const addOns = [
    // {title: "Additional Hours ", subtitle: "Operational hours", description:"Additional hours add on", value:"additional_hours", type:"time", price:100, new: false},
    {title: "Idle Time ", subtitle: "Non Operational Hours", description:"Non operational hours. If you choose to break down your rental time. For example, you book us for 3 hours. You want us to be in service from 5-6pm and stop service from 6-7pm for dinner and resume again from 7-9pm. So, the hour between 6-7pm, will be an additional charge. ", value:"idle_hours", type:"time", price:50, new: false},
    {title: "Guest Book", value:"guest_book", type:"product", description:"For every photo taken, we print an additional one to add to an album.", price:100, new:false},
    {title: "Photos In USB",value:"usb",  type:"product", description:"Digital event photos will be mailed to you on a USB.", price:30, new:false},
    {title: "Custom Print Template", value:"print_template", description:"You provide us with an idea/theme/text on how you would like the template would look like. Our designer will present you with two mock ups." , type:"service", price:50, new:false},
    {title: "Unlimited 4x6",value:"4x6_unlimited",  description:"Instead of our standard 2x6 strip prints, you can upgraded to 4x6 prints.", type:"service", price:50, new:false}
]

const eventType = [
    {type: "corporate", img:""},
    {type: "wedding", img:""},
    {type: "birthday", img:""},
    {type: "baby shower", img:""},
    {type: "other", img:""}
]

const eventService = [
    
    {type: "on-site", img:""}
    // {type: "virtual-booth", img:""},
]


const breakpoints = createBreakpoints({});
let theme = createMuiTheme({
    breakpoints,
    overrides: {
        // Style sheet name ⚛️
        MuiButton: {
            contained : {
                // background: 'linear-gradient(45deg, #4A4A4A 30%, #707070 90%)',
                borderRadius: 5,
                border: 0,
                // color: 'white',
                height: 48,
                padding: '0 30px',
                boxShadow: '0 3px 5px 2px rgba(119, 119, 119, 0.3)'
            },
            outlined : {
                background: 'linear-gradient(45deg, #f5f5f5 30%, # 90%)',
                borderRadius: 5,
                border: 0,
                borderColor: '#ffffff',
                height: 48,
                padding: '0 30px'
            }
        },
    },
    palette: {
        primary: {
            light: '#b24656',
            main: '#fab1a0',
            dark: '#ff8396',
            contrastText: '#fff',
        },
        secondary: {
            light: '#171717',
            main: '#212121',
            dark: '#4d4d4d',
            contrastText: '#fff',
        }
        //   https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=303F9F&secondary.color=F44336
    },
    typography: {
        fontSize: 16,
        fontWeight: 400,
        fontFamily: [
            'Montserrat',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif'
        ].join(','),
        h1 : {
            fontFamily: [
                'Merriweather'
            ].join(',')
        },
        h3 :{
            [breakpoints.up('md')]: {
                fontSize: '2.4rem',
            }
        }
    }
});

theme = responsiveFontSizes(theme);
const defualtDate = dayjs() 



export default class App extends Component {
    constructor(props) {
        super(props);
       
        this.state = {
            environment: process.env.NODE_ENV,
            mobile: false,
            backdrops:[],
            templates:[],
            addOns: addOns,
            eventTypes: eventType,
            eventService:eventService,
            albums:[],
            virtualBooth: {
                price: 200
            },
            booking:{
                client_first_name : "",
                client_last_name : "",
                client_email : "",
                package_base_price: 75,
                package_price: 275,
                package_hour: 2,
                package_add_on: [] ,
                package_backdrop: "",
                package_template: "",
                event_service: "on-site",
                event_type: "wedding",
                event_type_custom:"",
                event_venue_name :"",
                event_venue_address : "",
                event_guest_number : "0",
                event_date: defualtDate,
                event_day: dayjs(new Date()).format("MMMM DD YYYY"),
                event_start_time: dayjs(new Date()).format("hh:mmA"),
                additional_questions: ""
            }
        }
    }

    componentDidMount = () => {
        // Detect if mobile
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        
        // this.get("backdrops", "").then(responses => {
        //     this.setState({
        //         backdrops: responses
        //     })
        // })

        // this.get("templates", "").then(responses => {
        //     this.setState({
        //         templates: responses
        //     })
        // })

        this.handleUpdateBooking("event_end_time", dayjs(this.state.booking.event_date).add(this.state.booking.package_hour, 'h').format("hh:mmA"))        

    }

    handleGlobalStateChange = (value) => { 
        this.setState({...value});
    }


    resize() {
        let isMobile = (window.innerWidth <= 958);
        this.setState({mobile: isMobile});
    }


    get = (endpoint, params) => { 
      
            return window.fetch(`test-data/${endpoint}.json`)
            .then(res => res.json())
            .then(
                (result) => {
                    return Promise.resolve(result);
                },
                (error) => {
                    console.log(error)
                }
            ).catch(err => {
                return Promise.reject(err);
            }); 


        // comment this out for now to save cost
        // let url = new URL(process.env.REACT_APP_API_DOMAIN + endpoint);
        //     Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
        //     return window.fetch(url)
        //     .then(res => res.json())
        //     .then(
        //         (result) => {
        //             return Promise.resolve(result);
        //         },
        //         (error) => {
        //             console.log(error)
        //         }
        //     ).catch(err => {
        //         return Promise.reject(err);
        //     }); 

    }

    put = (endpoint, params) => { 
        let url = new URL(process.env.REACT_APP_API_DOMAIN + endpoint);

        return window.fetch(url, {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
            "Content-type": "application/json; charset=UTF-8"
            }
        })
        .then(res => res.json())
        .then(
            (result) => {
                return Promise.resolve(result);
            },
            (error) => {
                console.log(error)
            }
        ).catch(err => {
            return Promise.reject(err);
        }); 
    }

    handleUpdateBooking = (key, value) => {
        // console.log(key, value)
        let obj =  {}

        if(key === "package_hour") {
             obj = {
                package_hour: value,
                package_price: value + this.state.booking.package_base_price,
                event_end_time: dayjs(this.state.booking.event_date).add(value, 'h').format("hh:mmA")
             }
        } else if ( key === "event_date") {
            obj = {
                event_date : dayjs(value).format(),
                event_day : dayjs(value).format("MMMM DD YYYY"),
                event_start_time: dayjs(value).format("hh:mm A"),
                event_end_time: dayjs(value).add(this.state.booking.package_hour, 'h').format("hh:mmA")
            }
        } else {
            obj = {
                [key]: value
            }
        }

        this.setState({
            booking:{   
                ...this.state.booking,
                ...obj
            }
        })
    }


    render = () => {

        return (
                <ThemeProvider theme={theme}>
                    <BrowserRouter basename="/">
                        <ScrollToTop />
                        <Route render={({ location }) => {
                                return (
                                    <React.Fragment key={location} >
                                        <Controller>
                                                <Header history={location} {...this.state}/>
                                                <Switch location={location}>
                                                    <Route path="/home" from={location} exact render={(props) => <Home from={location} {...props} {...this.state} handleGlobalStateChange={this.handleGlobalStateChange} get={this.get} handleUpdateBooking={this.handleUpdateBooking} />}/>
                                                    <Route path="/pricing" from={location} exact render={(props) => <Pricing from={location} {...props} {...this.state} get={this.get} handleGlobalStateChange={this.handleGlobalStateChange}handleUpdateBooking={this.handleUpdateBooking}/>} />
                                                    <Route path="/backdrops" from={location} exact render={(props) => <Backdrops from={location} {...props} {...this.state} get={this.get} handleGlobalStateChange={this.handleGlobalStateChange}handleUpdateBooking={this.handleUpdateBooking}/>} />
                                                    <Route path="/templates" from={location} exact render={(props) => <Templates from={location} {...props} {...this.state} get={this.get} handleGlobalStateChange={this.handleGlobalStateChange}handleUpdateBooking={this.handleUpdateBooking}/>} />
                                                    <Route path="/albums" from={location} exact render={(props) => <Albums from={location}  {...props} {...this.state} get={this.get} handleGlobalStateChange={this.handleGlobalStateChange}handleUpdateBooking={this.handleUpdateBooking}/>} />
                                                     <Route path="/photobooth" from={location} exact render={(props) => <ServiceOnSite from={location}  {...props} {...this.state} get={this.get} handleGlobalStateChange={this.handleGlobalStateChange}handleUpdateBooking={this.handleUpdateBooking}/>} />
                                                    <Route path="/virtual-booth" from={location} exact render={(props) => <ServiceVirtualBooth from={location}  {...props} {...this.state} get={this.get} handleGlobalStateChange={this.handleGlobalStateChange}handleUpdateBooking={this.handleUpdateBooking}/>} />
                                                    <Route path="/contact" from={location} exact render={(props) => <Contact from={location} {...props} {...this.state} handleGlobalStateChange={this.handleGlobalStateChange}handleUpdateBooking={this.handleUpdateBooking}/>} />
                                                    <Route path="/client/:ch" from={location} exact render={(props) => <Client from={location} {...props} {...this.state}  get={this.get} handleGlobalStateChange={this.handleGlobalStateChange}handleUpdateBooking={this.handleUpdateBooking}/>} />
                                                    <Route path="/booking" from={location} exact render={(props) => <Booking  from={location} {...props} {...this.state} get={this.get} put={this.put} handleGlobalStateChange={this.handleGlobalStateChange} handleUpdateBooking={this.handleUpdateBooking}/>} />
                                                    <Route render={() => <Redirect to="/home" />} />
                                                </Switch>
                                            <Footer from={location}  {...this.state}/>
                                        </Controller>
                                    </React.Fragment>
                                )
                        }}
                        />
                    </BrowserRouter>
                </ThemeProvider>
        );
    }
}