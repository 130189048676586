import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid"
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';

import {
    MuiPickersUtilsProvider,
    TimePicker,
    DatePicker,
  } from '@material-ui/pickers';

import DateUtils from '@date-io/dayjs';
import './../LayoutBooking.scss';

const eventType = [
    {type: "wedding", img:"https://placedog.net/500"},
    {type: "birthday", img:"https://placedog.net/500"},
    {type: "baby shower", img:"https://placedog.net/500"}
]

export default class LayoutBookingEventInfo extends Component {

    renderEventType() {
        
        let output = [];
            eventType.forEach((event, i) => {
                let selected = this.props.booking.event_type === event.type ? "--selected" : null
                output.push(
                    <React.Fragment key={i}>
                        <Grid  item xs={4} md={2} lg={2}>           
                            <Box className={"event-type " + selected + (this.props.isDisabled ? " --disabled" : "")} onClick={this.props.handleCustomInputChange.bind(this, "event_type", event.type)}>
                                <Box className="event-type-image">
                                    <img src={event.img} alt={event.type + " type"}/>
                                </Box>
                                <Typography variant="caption" component="p">
                                    {event.type}
                                </Typography>
                            </Box>
                        </Grid>
                    </React.Fragment>
                )
            })
        return output
    }

    renderPackage() {
        
        let output = [];
        let i = 3;

            for (i = 0; i < 3; i++) {
                let packageNumber = i + 2
                let hour = packageNumber + "HR"
                let price = packageNumber + "75"
                let selected = this.props.booking.package ===  packageNumber ? "--selected" : null

                //  let selected = this.props.booking.package === event.type ? "--selected" : null
                    output.push(
                        <React.Fragment key={i}>
                            <Grid item xs={12} md={4} className={"package " + selected + (this.props.isDisabled ? " --disabled" : null)} onClick={this.props.handleCustomInputChange.bind(this, "package", packageNumber)}>
                                <Card elevation={0} square={true} >
                                    <Box className="item_top">
                                        <Typography className="package-title_price" variant="subtitle1" component="h6">
                                            {hour}
                                        </Typography>
                                        <Typography className="package-title_cat" variant="h5" component="h4">
                                            ${price}
                                        </Typography>
                                        
                                    </Box>
                                </Card>
                            </Grid>
                        </React.Fragment>
                )

            }

          
        return output
    }

    render() {
        let q_numberOfQuests = ""

            if(this.props.booking.event_service === "on-site") {
                q_numberOfQuests =
                <>
                <Grid item xs={12} md={6}>
                    <TextField 
                        required 
                        defaultValue={this.props.booking.event_guest_number} 
                        mim={10}
                        type="number"  
                        id="event_guest_number" 
                        name="event_guest_number" 
                        label="Approximately number of guests"  
                        variant="outlined"  
                        disabled={this.props.isDisabled}
                        onChange={this.props.handleInputChange} 
                        fullWidth 
                    />
                </Grid>
                
                <Grid item xs={12} md={12} className="f_footnote">
                    <Typography component="p">
                        *There will be an additional charge over 200 guest
                    </Typography>
                </Grid>
                </>
            } else {
                q_numberOfQuests = ""
            }
        return (
            <React.Fragment>
                <Grid  container spacing={3} className="f_client-information">
                        <Grid item xs={12} md={12}>
                            <Box className="f_header">
                                <Typography variant="h4" component="h3">
                                <span className="f_header_step">{this.props.idx}.</span>The Event 
                                </Typography>
                                <Typography>
                                    Just a bit of information about the venue so we know the size of the footprint. If the event is held in a house , it is not necessary to provide an address until later.  
                                </Typography>
                            </Box>
                        </Grid>
                    
                        {/* <Grid item xs={12} md={12}>
                            <Typography  variant="body2"  component="h5" className="f_header-subtitle">
                                Event Type
                            </Typography>
                            <Grid  container  spacing={3} >
                                {this.renderEventType()}  
                            </Grid>
                        </Grid> */}
                    
                        <Grid item xs={12} md={12}>
                            {/* <Typography  variant="body2"  component="h5" className="f_header-subtitle">
                                Event detail
                            </Typography> */}
                            <Grid  container  spacing={3} >
                                <Grid item xs={12} md={6}>
                                
                                    <TextField 
                                        defaultValue={this.props.booking.event_venue_name} 
                                        id="event_venue_name" 
                                        name="event_venue_name" 
                                        label="Venue Name" 
                                        variant="outlined" 
                                        disabled={this.props.isDisabled}
                                        onChange={this.props.handleInputChange} 
                                        fullWidth 
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField  
                                        defaultValue={this.props.booking.event_venue_address} 
                                        id="event_venue_address" 
                                        name="event_venue_address" 
                                        label="Venue Location"  
                                        variant="outlined"  
                                        disabled={this.props.isDisabled}
                                        onChange={this.props.handleInputChange} 
                                        fullWidth 
                                    />
                                </Grid>
                                <MuiPickersUtilsProvider utils={DateUtils}>
                                    <Grid item xs={12} md={6}>
                                        <DatePicker
                                            required
                                            format="MMMM DD YYYY"
                                            id="event_day"
                                            name="event_day"
                                            label="Event Date"
                                            value={this.props.booking.event_date}
                                            onChange={this.props.handleDateTimeChange}
                                            inputVariant="outlined"
                                            animateYearScrolling
                                            disabled={this.props.isDisabled}
                                            fullWidth
                                        />
                                
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                    <TimePicker
                                        required
                                        id="time-picker"
                                        label="Tentative Start Time"
                                        placeholder="08:00 AM"
                                        mask="__:__ _M"
                                        value={this.props.booking.event_date}
                                        onChange={this.props.handleDateTimeChange}
                                        disabled={this.props.isDisabled}
                                        inputVariant="outlined"
                                        fullWidth
                                    />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                                
                                
                            </Grid>
                        </Grid>
                        
                        {q_numberOfQuests}
                        
                    </Grid>
                {/* <Box className="f_navigation">
                        <Button variant="contained" color="primary" size="large" onClick={this.props.handleNext.bind(this, this.props.idx)}>
                            Next
                        </Button>
                </Box> */}
            </React.Fragment>
        );
    }
}